import { Button, ButtonTheme, DetailsRows, PageTitle, PageTitleSize } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForSubscriptionDetails } from '@chic/components';
import { FileFromViews, SubscriptionDetailsPageName, SubscriptionProductAvailability } from '@chic/enums';
import React from 'react';
import { Params, useParams } from 'react-router-dom';
import { useSubscriptionDetailsActions, useSubscriptionDetailsData } from '../hooks';
import { UseSubscriptionDetailsActions, UseSubscriptionDetailsData } from '../types';
import { SubscriptionProduct } from '@chic/models';
import { 
  InnerContainer, 
  PageTitleWrapper, 
  Section,  
  StyledTransactionProductBox, 
  TransactionProductBoxesWrapper, 
} from './subscriptionDetailsBasicData.styled';

export const SubscriptionDetailsBasicDataView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId }: Params = useParams();
  const { 
    detailsRowsData, 
    subscriptionDetails, 
    actionsPermissions, 
    redirectToEditProducts,
  }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const { cancelWholeSubscription }: UseSubscriptionDetailsActions = useSubscriptionDetailsActions();

  return (
    <BaseViewForSubscriptionDetails 
      activeTab={SubscriptionDetailsPageName.BasicData}    
      subscriptionId={subscriptionId}
      backgroundImage={FileFromViews.BaseViewForSubscriptionDetailsBasicDataBackground}
      isNewOrderButtonActive={!!actionsPermissions.createOrder}
      bottomButtons={[
        ...(subscriptionDetails?.id ? [{
          label: t('chic.management.subscriptionDetailsBasicData.bottomButtons.cancelWholeSubscription'),
          buttonTheme: ButtonTheme.ICTextRed,
          action: (): void => cancelWholeSubscription(subscriptionDetails.id),
          disabled: !actionsPermissions.cancelWholeSubscription,
        }] : []),
      ]}
    >
      <InnerContainer>
        <DetailsRows 
          rows={detailsRowsData} 
          description={t('chic.management.subscriptionDetailsBasicData.detailsRows.description')}
        />
        <Section>
          <PageTitleWrapper>
            <PageTitle 
              size={PageTitleSize.Small} 
              label={t('chic.management.subscriptionDetailsBasicData.products.title')} 
            />
            {!!actionsPermissions.updateProducts && (
              <Button 
                buttonTheme={ButtonTheme.ICText}
                label={t('chic.management.global.change')}
                onClick={redirectToEditProducts}
              />
            )}
          </PageTitleWrapper>
          <TransactionProductBoxesWrapper>
            {subscriptionDetails?.contract.products.map((product: SubscriptionProduct): JSX.Element => (
              <StyledTransactionProductBox 
                name={product.productName}
                category={product.categoryName}
                ean={product.ean}
                amount={product.amount}
                image={product.image ?? undefined}
                price={product.unitPrice}
                unavailable={![
                  SubscriptionProductAvailability.Available, 
                  SubscriptionProductAvailability.PartiallyAvailable,
                ].includes(product.availability)}  
                key={product.productId}
              />
            ))}
          </TransactionProductBoxesWrapper>
        </Section>
      </InnerContainer>
    </BaseViewForSubscriptionDetails>
  );
};
