import { SubscriptionPlanPropertyType } from '@chic/enums';
import { SubscriptionPlanProperty } from '@chic/types';

export const getPlanPropertyValueByType: <T extends number | boolean>(
  properties: SubscriptionPlanProperty[], type: SubscriptionPlanPropertyType
) => T | undefined = <T extends number | boolean>(
  properties: SubscriptionPlanProperty[], type: SubscriptionPlanPropertyType,
): T | undefined => {
  return properties.find((property: SubscriptionPlanProperty): boolean => property.type === type)?.value as T | undefined;
};
