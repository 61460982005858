import { BadgeColorTheme, Color } from '@chic-loyalty/ui';
import { SubscriptionOrderStatus, SubscriptionDeliveryStatus, SubscriptionPaymentStatus } from '@chic/enums';

export const orderStatusTheme: Record<SubscriptionOrderStatus, BadgeColorTheme> = {
  [SubscriptionOrderStatus.Complete]: BadgeColorTheme.Green,
  [SubscriptionOrderStatus.Failed]: BadgeColorTheme.Red,
  [SubscriptionOrderStatus.OnDelivery]: BadgeColorTheme.Yellow,
  [SubscriptionOrderStatus.Pending]: BadgeColorTheme.Blue,
  [SubscriptionOrderStatus.RefundRequest]: BadgeColorTheme.Gray,
  [SubscriptionOrderStatus.RefundRequestAccepted]: BadgeColorTheme.Dark,
  [SubscriptionOrderStatus.Refunded]: BadgeColorTheme.Navy,
  [SubscriptionOrderStatus.WaitingForPayment]: BadgeColorTheme.White,
};

export const mobileOrderStatusColor: Record<SubscriptionOrderStatus, Color> = {
  [SubscriptionOrderStatus.Complete]: Color.ICGreen,
  [SubscriptionOrderStatus.Failed]: Color.ICRed100,
  [SubscriptionOrderStatus.OnDelivery]: Color.ICYellow100,
  [SubscriptionOrderStatus.Pending]: Color.ICBlue,
  [SubscriptionOrderStatus.RefundRequest]: Color.ICGray500,
  [SubscriptionOrderStatus.RefundRequestAccepted]: Color.ICBlack100,
  [SubscriptionOrderStatus.Refunded]: Color.ICNavy,
  [SubscriptionOrderStatus.WaitingForPayment]: Color.ICWhite100,
};

export const deliveryStatusTheme: Record<SubscriptionDeliveryStatus, BadgeColorTheme> = {
  [SubscriptionDeliveryStatus.Collected]: BadgeColorTheme.Blue,
  [SubscriptionDeliveryStatus.Completed]: BadgeColorTheme.Green,
  [SubscriptionDeliveryStatus.Expired]: BadgeColorTheme.Red,
  [SubscriptionDeliveryStatus.InTransit]: BadgeColorTheme.Blue,
  [SubscriptionDeliveryStatus.Pending]: BadgeColorTheme.Yellow,
  [SubscriptionDeliveryStatus.Resend]: BadgeColorTheme.Yellow,
  [SubscriptionDeliveryStatus.Returned]: BadgeColorTheme.Gray,
  [SubscriptionDeliveryStatus.SendBack]: BadgeColorTheme.Yellow,
  [SubscriptionDeliveryStatus.Shipped]: BadgeColorTheme.Yellow,
  [SubscriptionDeliveryStatus.WaitingForSurcharge]: BadgeColorTheme.Yellow,
  [SubscriptionDeliveryStatus.WaitingForUpdate]: BadgeColorTheme.Yellow,
};

export const paymentStatusTheme: Record<SubscriptionPaymentStatus, BadgeColorTheme> = {
  [SubscriptionPaymentStatus.Failed]: BadgeColorTheme.Red,
  [SubscriptionPaymentStatus.Pending]: BadgeColorTheme.Yellow,
  [SubscriptionPaymentStatus.Refunded]: BadgeColorTheme.Blue,
  [SubscriptionPaymentStatus.Rejected]: BadgeColorTheme.Red,
  [SubscriptionPaymentStatus.Success]: BadgeColorTheme.Green,
};
