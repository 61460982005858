import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  Breakpoint, 
  UseState, 
  InputWithLocation, 
  ComponentColorTheme,
  MapPointDelivery, 
  ButtonTheme, 
  DeliveryPointMap,
  UseRedirect,
  useRedirect,
  UseNotifications,
  useNotifications,
  PageTitleSize,
  PageTitle,
  Button, 
} from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';
import { FileFromViews, QueryKey, RoutingPath, SessionStorage, SubscriptionPickupPointType } from '@chic/enums';
import { FrontendApiError, PickupPointDetails } from '@chic/models';
import { getPickupPointDetails } from '@chic/api';
import { useQuery } from 'react-query';
import { emptyRequest, isPickupPointType } from '@chic/utils';
import { useSubscriptionEditDeliveryPoint } from './subscriptionEditDeliveryPoint.hooks';
import { UseSubscriptionEditDeliveryPoint } from './subscriptionEditDeliveryPoint.types';
import { 
  Container, 
  InputWrapper, 
  InnerContainer, 
  SearchContainer, 
  OuterListContainer, 
  ListContainer, 
  StyledSearchDeliveryListItem, 
  ButtonWrapper, 
  StyledButton,
  MapContainer,
  StyledEmptyStateBanner,
  PageTitleWrapper, 
} from './subscriptionEditDeliveryPoint.styled';
import { Params, useParams } from 'react-router-dom';
import { useTitle } from '@chic/hooks';

export const SubscriptionEditDeliveryPointView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { subscriptionId }: Params = useParams();
  const [isMapEnabled, setIsMapEnabled]: UseState<boolean> = useState<boolean>(false);
  const listRef: RefObject<HTMLDivElement> = useRef(null);
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.subscriptionEditDeliveryPointView.title'));
  const pointId: string | undefined = useMemo(
    (): string | undefined => sessionStorage.getItem(SessionStorage.PickupPointId) ?? undefined,
    [sessionStorage],
  );
  const selectedDelivery: SubscriptionPickupPointType | null = useMemo(
    (): SubscriptionPickupPointType | null => {
      const selectedDeliveryTypeFromStorage: string | null = sessionStorage.getItem(SessionStorage.SelectedDeliveryType);
      if (!selectedDeliveryTypeFromStorage) {
        redirect(RoutingPath.SubscriptionEditDelivery);
        return null;
      }

      if (isPickupPointType(selectedDeliveryTypeFromStorage)) {
        return selectedDeliveryTypeFromStorage;
      } else {
        return null;
      }
    },
    [sessionStorage],
  );
  const {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPointId,
    onPositionChange,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition,
    onCloseStoreInfo,
    pointDetails,
    setPointDetails,
  }: UseSubscriptionEditDeliveryPoint = useSubscriptionEditDeliveryPoint(
    listRef, selectedDelivery, pointId,
  );

  useQuery(
    [QueryKey.PickupPointDetails, pointId],
    (): Promise<PickupPointDetails | void> => selectedDelivery && !!pointId
      ? getPickupPointDetails(parseInt(pointId, 10), selectedDelivery) 
      : emptyRequest(),
    {
      enabled: !!pointId && !!selectedDelivery,
      onSuccess: (data: PickupPointDetails): void => {
        setPointPosition([data.location?.latitude ?? 0, data.location?.longitude ?? 0]);
        setActivePointId(data.id);
        setPointDetails(data);
      },
      onError: (error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }),
    },
  );

  useEffect((): void => setIsMapEnabled(true), [mapPoints]);

  const onSavePickupPoint: () => void = (): void => {
    if (!pointDetails || !subscriptionId) {
      return;
    }

    sessionStorage.setItem(SessionStorage.PickupPointId, String(activePointId));
    redirect(RoutingPath.SubscriptionEditDelivery, { subscriptionId });
  };

  const goBack: () => void = (): void => {
    if (!subscriptionId) {
      redirect(RoutingPath.SubscriptionList);
      return;
    }

    redirect(RoutingPath.SubscriptionEditDelivery, { subscriptionId });
  };
  
  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          label={t('chic.management.subscriptionEditDeliveryPointView.pageTitle')} 
          onClick={goBack}
          size={PageTitleSize.Big}
        />
        <Button 
          label={t('chic.management.global.cancel')}
          onClick={goBack}
          buttonTheme={ButtonTheme.ICText}
        />
      </PageTitleWrapper>
      {isTablet && (
        <InputWrapper>
          <InputWithLocation
            inputValue={inputValue}
            onChange={onSearchInputChange}
            onLocationButtonClick={onLocationButtonClick}
            onPositionChange={onPositionChange}
            placeholder={t('chic.management.subscriptionEditDeliveryPointView.input.search')}
            colorTheme={ComponentColorTheme.IC}
          />
        </InputWrapper>
      )}
      <InnerContainer>
        {(!isTablet || (isTablet && !!mapPoints.length && (activePointId || inputValue))) && (
          <SearchContainer>
            {!isTablet && (
              <InputWithLocation
                inputValue={inputValue}
                onChange={onSearchInputChange}
                onLocationButtonClick={onLocationButtonClick}
                onPositionChange={onPositionChange}
                placeholder={t('chic.management.subscriptionEditDeliveryPointView.input.search')}
                colorTheme={ComponentColorTheme.IC}
              />
            )}
            {!!mapPoints.length && (!!activePointId || !!inputValue) ? (
              <OuterListContainer>
                <ListContainer ref={listRef}>
                  {mapPoints.map((point: MapPointDelivery): JSX.Element => (
                    <StyledSearchDeliveryListItem
                      data={point}
                      onChoose={(itemId: string): void => onSelectDeliveryPointId(parseInt(itemId, 10))}
                      radioButtonName={point.id.toString()}
                      checked={point.id === activePointId}
                      key={point.id}
                    />
                  ))}
                </ListContainer>
              </OuterListContainer>
            ) : !isTablet && (
              <OuterListContainer>
                <StyledEmptyStateBanner 
                  background={FileFromViews.SubscriptionEditDeliveryPointEmptyStateBackground} 
                  backgroundMobile={FileFromViews.SubscriptionEditDeliveryPointEmptyStateBackgroundMobile} 
                  text={t('chic.management.subscriptionEditDeliveryPointView.emptyStateBanner.text')}                
                />
              </OuterListContainer>
            )}
            {!isTablet && (
              <ButtonWrapper>
                <StyledButton
                  label={t('chic.management.global.confirm')}
                  fullWidth={isMobile}
                  onClick={onSavePickupPoint}
                  disabled={!activePointId}
                  buttonTheme={ButtonTheme.ICPrimary}
                />
              </ButtonWrapper>
            )}
          </SearchContainer>
        )}
        {isMapEnabled && (isTablet ? (!!activePointId || !!inputValue) : true) ? (
          <MapContainer>
            <DeliveryPointMap
              points={mapPoints}
              onSelectDeliveryPoint={onSelectDeliveryPointId}
              onCloseStoreInfo={onCloseStoreInfo}
              enable
              currentPosition={pointPosition}
              shouldFlyToPosition={shouldFlyToPoint}
              onButtonChooseStore={onSavePickupPoint}
              onCoordinatesChange={(lat: number, lng: number): void => {
                setPointPosition([lat, lng]);
                setShouldFlyToPoint(false);
              }}
            />
          </MapContainer>
        ) : (
          <StyledEmptyStateBanner 
            background={FileFromViews.SubscriptionEditDeliveryPointEmptyStateBackground} 
            backgroundMobile={FileFromViews.SubscriptionEditDeliveryPointEmptyStateBackgroundMobile} 
            text={t('chic.management.subscriptionEditDeliveryPointView.emptyStateBanner.text')}                
          />
        )}
      </InnerContainer>
      {!!isTablet && (
        <ButtonWrapper>
          <StyledButton
            label={t('chic.management.global.confirm')}
            fullWidth={isMobile}
            onClick={onSavePickupPoint}
            disabled={!activePointId}
            buttonTheme={ButtonTheme.ICPrimary}
          />
        </ButtonWrapper>
      )}
    </Container>
  );
};
