import { FileFromViews, SubscriptionPaymentType } from '@chic/enums';

export const getPaymentTypeLogo: (type: SubscriptionPaymentType) => FileFromViews = (type: SubscriptionPaymentType): FileFromViews => {
  switch (type) {
    case SubscriptionPaymentType.Payu:
    case SubscriptionPaymentType.PayuPending:
      return FileFromViews.PaymentLogoPayu;
    case SubscriptionPaymentType.Przelewy24:
    case SubscriptionPaymentType.Przelewy24Pending:
      return FileFromViews.PaymentLogoPrzelewy24;
    default:
      return FileFromViews.PaymentLogoOnline;
  }
};
