import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Container } from './adAdd.styled';
import { Params, useParams } from 'react-router-dom';
import { 
  AddFileTheme, 
  ButtonTheme, 
  EditItemRow, 
  FileSettingsBox, 
  FileSettingsBoxesTheme,
  IconName,
  Loader, 
  useRedirect, 
  UseRedirect, 
} from '@chic-loyalty/ui';
import { 
  FileTypeExtended, 
  RoutingPath, 
  SetupType, 
  UploadAssetType, 
} from '@chic/enums';
import { AssetDetails, ScreenObject, UseAnimations } from '@chic/models';
import { BaseViewForAnimations } from '@chic/components';
import { useAnimations, useTitle } from '@chic/hooks';
import { stringify } from 'query-string';

export const AdAddView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { type }: Params = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    onAddFile, 
    onSaveFile, 
    onSetAnimationName, 
    animationName, 
    requirements, 
    savedFiles, 
    onCreateNewSetup,
    screens,
    targetOptions,
    acceptedFilesTypes,
    onSavedFilesChangePositions,
    onDeleteSavedFile,
    onChangeSelectedTargets,
    onEditFile,
    fileToEdit,
    resetUploadedData, 
    onAddNewItem,
    buttonBanners,
    selectedTargets,
    onDeleteScreen,
    setupType,
    transformAssetDetailsToAddFileInitial,
    onStateReset,
    uploadErrorMessage,
  }: UseAnimations = useAnimations(
    type as SetupType, 
    type === SetupType.Tv ? UploadAssetType.TVAnimation : UploadAssetType.TabletAnimation,
  );
  useTitle(t('chic.management.adAddView.title'));

  const changeSetupType: () => void = (): void => {
    onStateReset();
    redirect(RoutingPath.AdAdd, { type: setupType === SetupType.Tablet ? SetupType.Tv : SetupType.Tablet });
  };

  return (
    <Container>
      {!setupType ? <Loader /> : (
        <BaseViewForAnimations 
          title={t(`chic.management.adAddView.${setupType}.title`)} 
          goBack={(): void => redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`)} 
          headerButton={{
            label: t(`chic.management.adAddView.${setupType}.headerButton.label`),
            onClick: changeSetupType,
          }}
          animationName={animationName}
          onNameChange={onSetAnimationName} 
          addFileProps={{ 
            title: t('chic.management.global.addFile'),
            addFile: onAddFile,
            saveFile: onSaveFile,
            theme: type as AddFileTheme,
            requirements,
            acceptedFilesTypes,
            maxImageSizeInBytes: 2 * 1024 * 1024,
            maxVideoSizeInBytes: 15 * 1024 * 1024,
            withDurationChange: setupType === SetupType.Tv
              ? 'always'
              : 'conditionally',
            resetData: resetUploadedData,
            initialData: transformAssetDetailsToAddFileInitial(fileToEdit),
            errorMessage: uploadErrorMessage,
          }} 
          fileSettingsBoxesProps={{
            title: t('chic.management.adAddView.fileSettingsBoxesProps.title'),
            description: t('chic.management.adAddView.fileSettingsBoxesProps.description'),
            items: savedFiles.map((savedFile: AssetDetails): FileSettingsBox => ({
              id: savedFile.id,
              name: savedFile.name,
              path: savedFile.path,
              isVideo: savedFile.type === FileTypeExtended.Video,
            })),
            onAddNewItem,
            onItemDelete: onDeleteSavedFile,
            onItemEdit: onEditFile,
            onPositionChange: onSavedFilesChangePositions,
            theme: setupType === SetupType.Tv ? FileSettingsBoxesTheme.Horizontal : FileSettingsBoxesTheme.Vertical,
            addNewButtonLabel: t('chic.management.adAddView.fileSettingsBoxesProps.addNewButtonLabel'),
          }} 
          nameInputLabel={t('chic.management.adAddView.nameInputLabel')} 
          buttonBanners={buttonBanners}
          itemPickerSettings={{
            title: t('chic.management.adAddView.itemPickerSettings.title'),
            options: targetOptions,
            onDelete: onChangeSelectedTargets,
            onSelect: onChangeSelectedTargets,
            initialTargets: selectedTargets,
          }}
          screensRows={screens.map((screen: ScreenObject): EditItemRow => ({
            icon: IconName.Ads,
            details: {
              label: t('chic.management.adAddView.screensRows.label'),
              value: screen.name,
            },
            buttons: [
              {
                label: t('chic.management.adAddView.screensRows.edit'),
                onClick: (): void => redirect(RoutingPath.AdEditScreen, { type: setupType, screenType: screen.type, id: screen.id }),
                buttonTheme: ButtonTheme.ICTextWhite,
              },
              {
                label: t('chic.management.adAddView.screensRows.delete'),
                onClick: (): void => onDeleteScreen(screen.id),
                buttonTheme: ButtonTheme.ICTextBlue,
              },
            ],
          }))}
          acceptButtonSettings={{
            label: t('chic.management.global.save'),
            action: onCreateNewSetup,
            disabled: !animationName || !savedFiles.length,
          }} 
          cancelButtonSettings={{
            label: t('chic.management.global.cancel'),
            action: (): void => redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`),
          }}        
          withBackgroundImage
        />
      )}
    </Container>
  );
};
