import { SubscriptionDetailsAction } from '@chic/enums';
import { SubscriptionDetailsActions, SubscriptionDetailsState } from '@chic/models';

export const subscriptionDetailsReducer: (
  state: SubscriptionDetailsState, action: SubscriptionDetailsActions
) => SubscriptionDetailsState = (
  state: SubscriptionDetailsState, action: SubscriptionDetailsActions,
): SubscriptionDetailsState => {
  switch (action.type) {
    case SubscriptionDetailsAction.ClearUserEditProductsData:
      return { ...state, userEditProductsData: undefined };
    case SubscriptionDetailsAction.UpdateSubscriptionDetailsData:
      return { ...state, subscriptionDetails: action.payload };
    case SubscriptionDetailsAction.UpdateUserEditProductsData:
      return { ...state, userEditProductsData: action.payload };
    case SubscriptionDetailsAction.UpdateSubscriptionPlans:
      return { ...state, subscriptionPlans: action.payload };
    case SubscriptionDetailsAction.UpdateUserSubscriptionPlan:
      return { ...state, userSubscriptionPlan: action.payload };
    case SubscriptionDetailsAction.UpdateUserSubscriptionPlanProductsMap:
      return { ...state, userSubscriptionPlanProductsMap: action.payload };
    default: 
      return state;
  }
};
