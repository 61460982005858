import { Breakpoint, CalcSize, Color, Grid, Input, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px 24px 0;
  
  @media ${Breakpoint.Mobile} {
    padding: 30px 24px;
    border-bottom: 1px solid ${Color.ICGray500};
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  width: 100%;
  padding: 0 24px;
  margin: 62px 0;

  @media ${Breakpoint.Mobile} {
    margin: 40px 0;
  }
`;

export const ButtonsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 16px;
    padding: 28px 20px;
  }
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 48px;
`;

export const InputsRow: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const PostalCodeInput: StyledComponent<typeof Input> = styled(Input)`
  max-width: 160px;
  width: 100%;
`;
