import { SubscriptionDetailsContextType } from '@chic/models';
import { Context, createContext } from 'react';

export const SubscriptionDetailsContext: Context<SubscriptionDetailsContextType> = createContext<SubscriptionDetailsContextType>(
  [{ 
    subscriptionDetails: null, 
    subscriptionPlans: [],
    userSubscriptionPlan: null, 
    userSubscriptionPlanProductsMap: null, 
  }, (): void => undefined],
);
