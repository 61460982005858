import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForAnimations } from '@chic/components';
import { Params, useParams } from 'react-router-dom';
import { FileTypeExtended, AdScreenType, RoutingPath, SetupType, UploadAssetType, AnimationStateType } from '@chic/enums';
import { AddFileTheme, FileSettingsBox, FileSettingsBoxesTheme, Loader, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import { AssetDetails, UseAnimations, UseAnimationsContext } from '@chic/models';
import { useAnimations, useAnimationsContext, useTitle } from '@chic/hooks';

export const AdEditScreenView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { type, screenType, id }: Params = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    onAddFile, 
    onSaveFile, 
    onSetAnimationName, 
    requirements, 
    savedFiles, 
    onEditScreen, 
    animationName, 
    onSavedFilesChangePositions, 
    onDeleteSavedFile,
    acceptedFilesTypes,
    resetUploadedData,
    fileToEdit,
    onAddNewItem,
    onEditFile,
    targetsForButtons,
    setupType,
    transformAssetDetailsToAddFileInitial,
    uploadErrorMessage,
  }: UseAnimations = useAnimations(
    type as SetupType, 
    screenType === AdScreenType.AnimationsSlider ? UploadAssetType.TVAnimation : UploadAssetType.TVButton,
    screenType as AdScreenType, 
    undefined,
    id,
  );
  const { animationData }: UseAnimationsContext = useAnimationsContext();
  useTitle(t('chic.management.adEditScreenView.title'));

  const goBackToPreviousPage: () => void = (): void => {
    if (!animationData.type) {
      return;
    }
    
    redirect(animationData.type === AnimationStateType.Create 
      ? RoutingPath.AdAdd 
      : RoutingPath.AdEdit, { 
      type: type ?? '',
      id: animationData.setupId ?? '',
    });
  };
  
  return (
    <>
      {!setupType ? <Loader /> : (
        <BaseViewForAnimations 
          title={t(`chic.management.adEditScreenView.${screenType}.title`)}
          goBack={goBackToPreviousPage}
          nameInputLabel={t('chic.management.adEditScreenView.nameInputLabel')}
          onNameChange={onSetAnimationName}
          addFileProps={{ 
            title: t('chic.management.global.addFile'),
            theme: screenType === AdScreenType.AnimationsSlider ? AddFileTheme.TV : AddFileTheme.Button,
            maxImageSizeInBytes: 2 * 1024 * 1024,
            maxVideoSizeInBytes: screenType === AdScreenType.AnimationsSlider ? 15 * 1024 * 1024 : undefined,
            addFile: onAddFile,
            saveFile: onSaveFile,
            requirements,
            acceptedFilesTypes,
            withDurationChange: screenType === AdScreenType.AnimationsSlider
              ? 'conditionally'
              : 'never',
            dropdownOptions: screenType === AdScreenType.CategoriesList ? targetsForButtons : undefined,
            resetData: resetUploadedData,
            initialData: transformAssetDetailsToAddFileInitial(fileToEdit),
            errorMessage: uploadErrorMessage,
          }}
          fileSettingsBoxesProps={{
            title: t(`chic.management.adEditScreenView.fileSettingsBoxesProps.${screenType}.title`),
            description: t(`chic.management.adEditScreenView.fileSettingsBoxesProps.${screenType}.description`),
            items: savedFiles.map((savedFile: AssetDetails): FileSettingsBox => ({
              name: savedFile.name,
              path: savedFile.path,
              isVideo: savedFile.type === FileTypeExtended.Video,
              id: savedFile.id,
            })),
            onAddNewItem,
            onItemDelete: onDeleteSavedFile,
            onItemEdit: onEditFile,
            onPositionChange: onSavedFilesChangePositions,
            theme: FileSettingsBoxesTheme.Horizontal,
            addNewButtonLabel: t(`chic.management.adEditScreenView.fileSettingsBoxesProps.${screenType}.addNewButtonLabel`),
          }}
          acceptButtonSettings={{
            label: t('chic.management.global.save'),
            action: onEditScreen,
          }}
          cancelButtonSettings={{
            label: t('chic.management.global.cancel'),
            action: goBackToPreviousPage,
          }}
          animationName={animationName}
        />
      )}
    </>
  );
};
