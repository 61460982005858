import { Color, FontWeight, StyledComponent, TransactionProductBox } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { StatusDotProps } from './subscriptionDetailsOrdersHistory.types';

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const ChildrenContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;
  border-top: 1px solid ${Color.ICGray500};
`;

export const ProductsWrapper: StyledComponent<'div'> = styled.div``;

export const ChildrenLabel: StyledComponent<'p'> = styled.p`
  margin: 18px 0;
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${Color.ICWhite100};
`;

export const HistorySimpleTables: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StatusDot: StyledComponent<'div', StatusDotProps> = styled.div<StatusDotProps>`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${({ $backgroundColor }: StatusDotProps): Color => $backgroundColor};
`;
