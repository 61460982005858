import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useTitle } from '@chic/hooks';
import {
  Container,
  ContentWrapper,
  ContentBox,
  Table,
  CodesAmountText,
  CodesAmount,
  TableItemsWrapper,
  TableCode,
  TableExpirationWrapper,
  TablePromoCodeWrapper,
  TableCodeMobile,
  TableMobileDescription,
  SearchWrapper,
  StyledStatus,
  StyledPagination,
  StyledButton,
  StyledInput,
  StyledResultInfoBox,
  MobileCodesList,
  MobileCodesItem,
  MobileCodesRow,
  MobileCodesCode,
  Dot,
  MobileCodesLabel,
  MobileCodesButtonWrapper,
  MobileCodesValue,
} from './discountCodesSearch.styled';
import { 
  ComponentColorTheme, 
  TabSwitch, 
  IconName, 
  ResultInfoBox, 
  ElementsTable,
  PaginationDetails,
  usePagination,
  TabSwitchOption,
  ElementsTableColumn,
  ElementsTableRowData,
  Icon,
  Color,
  ButtonTheme,
  eanRegexp,
  internationalPhoneRegExp,
  emailRegexp,
  UseState,
  PageTitleSize,
  MenuPosition,
  Breakpoint,
  Button,
  PageTitle,
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from 'react-query';
import { ListPagination, DiscountCode, DiscountCodeDetails } from '@chic/models';
import { getCodeDetails, getCodes } from '@chic/api';
import { FileFromViews, QueryKey } from '@chic/enums';
import { discountTabs, inputProps, statusProps, tableHeader } from './discountCodesSearch.const';
import { DiscountCodeCellsNames, DiscountCodesSearchTab, DiscountCodesOrder } from './discountCodesSearch.enum';
import { useSearchParams } from 'react-router-dom';
import { UseSearchParams } from '@chic/types';
import { DiscountCodeDetailsComponent } from '@chic/components';
import { useMediaQuery } from 'react-responsive';

export const DiscountCodesSearchView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [activeTab, setActiveTab]: UseState<DiscountCodesSearchTab> = useState<DiscountCodesSearchTab>(DiscountCodesSearchTab.Code);
  const [codeDetails, setCodeDetails]: UseState<DiscountCodeDetails | null> = useState<DiscountCodeDetails | null>(null);
  const [codes, setCodes]: UseState<ListPagination<DiscountCode> | null> = useState<ListPagination<DiscountCode> | null>(null);
  const [tableRow, setTableRow]: UseState<ElementsTableRowData[]> = useState<ElementsTableRowData[]>([]);
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const [inputValue, setInputValue]: UseState<string> = useState<string>(searchParams.get('value') ?? '');
  const [isSearching, setIsSearching]: UseState<boolean> = useState<boolean>(false);
  const [searchError, setSearchError]: UseState<boolean> = useState<boolean>(false);
  const [sort, setSort]: UseState<DiscountCodeCellsNames> = useState<DiscountCodeCellsNames>(DiscountCodeCellsNames.Code);
  const [order, setOrder]: UseState<DiscountCodesOrder> = useState<DiscountCodesOrder>(DiscountCodesOrder.Asc);
  const { setMaxItems, setPage, itemsPerPage, offset, page, pagesCount }: PaginationDetails = usePagination();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  
  useTitle(t('chic.management.discountCodeSearchView.title'));
  
  const { isFetching: codeDetailsFetching }: UseQueryResult = useQuery(
    [QueryKey.DiscountCodes, isSearching, activeTab],
    (): Promise<DiscountCodeDetails> => getCodeDetails(inputValue),
    {
      enabled: isSearching && activeTab === DiscountCodesSearchTab.Code && !!inputValue.length,
      onSuccess: (data: DiscountCodeDetails): void => {
        setCodeDetails(data);
        setIsSearching(false);
        setSearchError(false);
      },
      onError: (): void => {
        setIsSearching(false);
        setSearchError(true);
      },
    },
  );
  
  const { isFetching: codesFetching }: UseQueryResult = useQuery(
    [QueryKey.DiscountCodes, isSearching, activeTab, offset, inputValue],
    (): Promise<ListPagination<DiscountCode>> => getCodes({ query: inputValue, offset, limit: itemsPerPage, sort, order }),
    {
      enabled: isSearching && activeTab !== DiscountCodesSearchTab.Code && !!inputValue.length,
      onSuccess: (data: ListPagination<DiscountCode>): void => {
        setSearchParams({ value: inputValue });
        setCodes(data);
        setMaxItems(data.amount);
        setIsSearching(false);
        setSearchError(false);
        const tableRows: ElementsTableRowData[] = data.list.map((item: DiscountCode): ElementsTableRowData => ({
          nodes: [
            <TableCode>
              <Icon size={20} name={IconName.BigPercent} color={Color.ICYellow100} />
              {item.code}
            </TableCode>,
            <TableItemsWrapper>
              <TableCodeMobile>
                {item.code}
              </TableCodeMobile>
              <TableExpirationWrapper>
                <TableMobileDescription>{t('chic.management.global.expirationTo')}</TableMobileDescription>
                {item.expirationDate ?? t('chic.management.global.indefinite')}
              </TableExpirationWrapper>
              <TablePromoCodeWrapper>
                <TableMobileDescription>
                  {`${t('chic.management.discountCodeSearchView.table.promotion.label')}:`}
                </TableMobileDescription>
                {item.promotionName}
              </TablePromoCodeWrapper>
            </TableItemsWrapper>,
            item.promotionName,
            <StyledStatus
              label={t(statusProps[item.status].label)}
              color={statusProps[item.status].color}
              dotColor={statusProps[item.status].dotColor}
              colorTheme={ComponentColorTheme.IC}
            />,
            // TODO: add this path to enum
            <MenuPosition internalPath={`/kody-rabatowe/${item.code}`}>
              <Icon name={IconName.ArrowRight} size={20} color={Color.ICYellow100} />
            </MenuPosition>,
          ],
        }));
        setTableRow(tableRows);
      },
      onError: (): void => {
        setIsSearching(false);
        setSearchError(true);
      },
    },
  );

  const handleSearch = (): void => {
    setIsSearching(true);
    setSort(DiscountCodeCellsNames.Code);
    setOrder(DiscountCodesOrder.Asc);
  };


  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    setIsSearching(false);
    if (event.key === 'Enter' && !!inputValue.length) {
      handleSearch();
    }
  };

  const handleChange = (value: string): void => {
    setInputValue(value);
  };

  const handleInputClear = (): void => {
    setCodes(null);
    setInputValue('');
  };

  const handleTabChange = (tabName: string): void => {
    handleInputClear();
    setCodeDetails(null);
    setSearchError(false);
    setActiveTab(tabName as DiscountCodesSearchTab);
  };

  const handlePageChange = (targetPage: number): void => {
    setIsSearching(true);
    setPage(targetPage);
  };

  const handleSort = (name: string): void => {
    const columnName: DiscountCodeCellsNames = name as DiscountCodeCellsNames;
    setIsSearching(true);
    if (sort !== DiscountCodeCellsNames.Code) {
      if (order === DiscountCodesOrder.Asc) {
        setOrder(DiscountCodesOrder.Desc);
      } else {
        setOrder(DiscountCodesOrder.Asc);
      }
    }
    setSort(columnName);   
  };

  const initialTab: DiscountCodesSearchTab = useMemo(
    (): DiscountCodesSearchTab => {
      if (searchParams.get('value')?.match(eanRegexp) || searchParams.get('value')?.match(emailRegexp)) {
        return DiscountCodesSearchTab.Mail;
      }
      if (searchParams.get('value')?.match(internationalPhoneRegExp)) {
        return DiscountCodesSearchTab.Phone;
      }
      return DiscountCodesSearchTab.Code;
    },
    [searchParams],
  );

  useEffect(
    (): void => {
      if (searchParams.get('value')?.match(eanRegexp) || searchParams.get('value')?.match(emailRegexp)) {
        setActiveTab(DiscountCodesSearchTab.Mail);
        setIsSearching(true);
      }
      if (searchParams.get('value')?.match(internationalPhoneRegExp)) {
        setActiveTab(DiscountCodesSearchTab.Phone);
        setIsSearching(true);
      }
    },
    [searchParams],
  );


  return (
    <Container>
      <ContentWrapper>
        <PageTitle size={PageTitleSize.Big} label={t('chic.management.discountCodeSearchView.header')} />
        <ContentBox>
          <TabSwitch 
            label={t('chic.management.discountCodeSearchView.tabSwitch.header')}
            tabs={discountTabs.map((tab: TabSwitchOption): TabSwitchOption => ({
              ...tab,
              labels: tab.labels.map((label: string): string => t(label)),
            }))} 
            initialTabName={initialTab}
            onTabChange={handleTabChange}
          />
          <SearchWrapper>
            <StyledInput
              {...inputProps[activeTab]}
              icon={IconName.Search}
              placeholder={t(inputProps[activeTab].placeholder ?? '')}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              disabled={codesFetching || codeDetailsFetching}
              value={inputValue}
            />
            <StyledButton
              label={!isMobile ? t('chic.management.discountCodeSearchView.search') : undefined}
              buttonTheme={ButtonTheme.ICPrimary}
              icon={IconName.Search}
              onClick={handleSearch}
            />
          </SearchWrapper>
        </ContentBox>
        {!searchError && !codes && !codeDetails && !codesFetching && !codeDetailsFetching && (
          <StyledResultInfoBox
            backgroundImage={FileFromViews.DiscountCodeSearchDevices}
            text={t('chic.management.discountCodeSearchView.resultInfoBox.searchInfo.message')}
          />
        )}
        {searchError && !codesFetching && !codeDetailsFetching && (
          <StyledResultInfoBox
            backgroundImage={FileFromViews.DiscountCodeSearchDevices}
            text={t('chic.management.discountCodeSearchView.resultInfoBox.noResult.message')}
          />
        )}
        {!searchError && !!codes && !codesFetching && activeTab !== DiscountCodesSearchTab.Code && (
          <Table>
            <CodesAmountText>
              {t('chic.management.discountCodeSearchView.codesFound')}
              <CodesAmount>{` (${codes?.amount})`}</CodesAmount>
            </CodesAmountText>
            {isMobile ? (
              <MobileCodesList>
                {codes.list.map((item: DiscountCode, index: number): JSX.Element => (
                  <MobileCodesItem key={index}>
                    <MobileCodesRow>
                      <MobileCodesCode>
                        <Icon size={20} name={IconName.BigPercent} color={Color.ICYellow100} />
                        {item.code}
                      </MobileCodesCode>
                      <Dot $background={statusProps[item.status].dotColor ?? Color.ICYellow100} />
                    </MobileCodesRow>
                    <MobileCodesRow>
                      <MobileCodesLabel>{t('chic.management.discountCodeSearchView.table.expirationDate.label')}</MobileCodesLabel>
                      <MobileCodesValue>{item.expirationDate ?? t('chic.management.global.indefinite')}</MobileCodesValue>
                    </MobileCodesRow>
                    <MobileCodesRow>
                      <MobileCodesLabel>{t('chic.management.discountCodeSearchView.table.promotion.label')}</MobileCodesLabel>
                      <MobileCodesValue>{item.promotionName}</MobileCodesValue>
                    </MobileCodesRow>
                    <MobileCodesButtonWrapper>
                      {/* TODO: add this path to enum */}
                      <MenuPosition internalPath={`/kody-rabatowe/${item.code}`}>
                        <Button
                          label={t('chic.management.discountCodeSearchView.table.button.details')}
                          buttonTheme={ButtonTheme.ICPrimary}
                          fullWidth
                        />
                      </MenuPosition>
                    </MobileCodesButtonWrapper>
                  </MobileCodesItem>
                ))}
              </MobileCodesList>
            ) : (
              <ElementsTable 
                columns={tableHeader.map((column: ElementsTableColumn): ElementsTableColumn => ({
                  ...column,
                  label: column.label && t(column.label),
                }))} 
                rows={tableRow}
                colorTheme={ComponentColorTheme.IC}
                onSort={handleSort}
              />
            )}
            {pagesCount > 1 && (
              <StyledPagination
                activePage={page}
                pagesCount={pagesCount}
                onActivePageChange={handlePageChange}
                colorTheme={ComponentColorTheme.IC}
              />
            )}
          </Table>
        )}
        {!searchError && !!codeDetails && !codeDetailsFetching && activeTab === DiscountCodesSearchTab.Code && (
          <DiscountCodeDetailsComponent discountCode={codeDetails.code} />
        )}
        {(codeDetailsFetching || codesFetching) && (
          <ResultInfoBox
            backgroundImage={FileFromViews.DiscountCodeSearchDevices}
            text={t('chic.management.discountCodeSearchView.resultInfoBox.isSearching.message')}
          />
        )}
      </ContentWrapper>
    </Container>
  );
};
