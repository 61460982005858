import { UseState } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { getAdsStatic, getSubscriptionsStatics } from '@chic/api';
import { 
  AdPosType,
  AdsTargetType,
  CyclicOrderPeriod,
  FileFromViews,
  PosPaymentOptions,
  QueryKey, 
  SubscriptionDeliveryStatus, 
  SubscriptionDeliveryType, 
  SubscriptionOrderStatus, 
  SubscriptionParameterType, 
  SubscriptionPaymentStatus, 
  SubscriptionPaymentType,
} from '@chic/enums';
import { 
  AdsDictionary, 
  AdsStaticDefinition, 
  DeliveryStaticDefinition, 
  ParameterValue, 
  StaticDefinition, 
  SubscriptionsStatics,
  UseStatics, 
} from '@chic/models';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { SubscriptionStaticParameter } from '@chic/types';

export const useStatics: () => UseStatics = (): UseStatics => {
  const { t }: TransProps<never> = useTranslation();
  const [deliveries, setDeliveries]: UseState<DeliveryStaticDefinition[]> 
    = useState<DeliveryStaticDefinition[]>([]);
  const [deliveryStatuses, setDeliveryStatuses]: UseState<StaticDefinition<SubscriptionDeliveryStatus>[]> 
    = useState<StaticDefinition<SubscriptionDeliveryStatus>[]>([]);
  const [paymentStatuses, setPaymentStatuses]: UseState<StaticDefinition<SubscriptionPaymentStatus>[]> 
    = useState<StaticDefinition<SubscriptionPaymentStatus>[]>([]);
  const [orderStatuses, setOrderStatuses]: UseState<StaticDefinition<SubscriptionOrderStatus>[]> 
    = useState<StaticDefinition<SubscriptionOrderStatus>[]>([]);
  const [posList, setPosList]: UseState<AdsStaticDefinition<AdPosType>[]> = useState<AdsStaticDefinition<AdPosType>[]>([]);
  const [targetingList, setTargetingList]: UseState<AdsStaticDefinition<AdsTargetType>[]> 
    = useState<AdsStaticDefinition<AdsTargetType>[]>([]);
  const [parameters, setParameters]: UseState<SubscriptionStaticParameter[]> = useState<SubscriptionStaticParameter[]>([]);

  useQuery(
    [QueryKey.SubscriptionsStatics],
    (): Promise<SubscriptionsStatics> => getSubscriptionsStatics(),
    {
      onSuccess: (staticData: SubscriptionsStatics): void => {
        setOrderStatuses(staticData.orderStatuses);
        setDeliveryStatuses(staticData.deliveryStatuses);
        setPaymentStatuses(staticData.paymentStatuses);
        setDeliveries(staticData.deliveryTypes);
        setParameters(staticData.parameters);
      },
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.AdsStatics],
    (): Promise<AdsDictionary> => getAdsStatic(),
    {
      onSuccess: (data: AdsDictionary): void => {
        setTargetingList(data.targeting);
        setPosList(data.pos);
      },
      onError: (): void => undefined,
    },
  );

  const getDeliveryNameByType: (type: SubscriptionDeliveryType) => string = (type: SubscriptionDeliveryType): string => {
    return deliveries.find((delivery: StaticDefinition<SubscriptionDeliveryType>): boolean => delivery.code === type)?.name ?? '';
  };

  const getDeliveryLogoByType: (type: SubscriptionDeliveryType) => string = (type: SubscriptionDeliveryType): string => {
    return deliveries.find((delivery: StaticDefinition<SubscriptionDeliveryType>): boolean => delivery.code === type)?.icon ?? '';
  };

  const getPaymentStatusByType: (type: SubscriptionPaymentStatus) => string = (type: SubscriptionPaymentStatus): string => {
    return paymentStatuses.find((payment: StaticDefinition<SubscriptionPaymentStatus>): boolean => payment.code === type)?.name ?? '';
  };

  const getDeliveryStatusByType: (type: SubscriptionDeliveryStatus) => string = (type: SubscriptionDeliveryStatus): string => {
    return deliveryStatuses.find((delivery: StaticDefinition<SubscriptionDeliveryStatus>): boolean => delivery.code === type)?.name ?? '';
  };

  const getOrderStatusByType: (type: SubscriptionOrderStatus) => string = (type: SubscriptionOrderStatus): string => {
    return orderStatuses.find((order: StaticDefinition<SubscriptionOrderStatus>): boolean => order.code === type)?.name ?? '';
  };

  const getPaymentNameByType: (type: SubscriptionPaymentType) => string = (type: SubscriptionPaymentType): string => {
    switch (type) {
      case SubscriptionPaymentType.Card:
        return t('chic.management.global.cardPayment'); 
      case SubscriptionPaymentType.Payu:
      case SubscriptionPaymentType.PayuPending:
        return t('chic.management.global.payU');
      case SubscriptionPaymentType.Przelewy24:
      case SubscriptionPaymentType.Przelewy24Pending:
        return t('chic.management.global.przelewy24');
      case SubscriptionPaymentType.ManuallyAccepted:
        return t('chic.management.global.manuallyAccepted');
      case SubscriptionPaymentType.PosPayment:
        return t('chic.management.global.posPayment');
      default: 
        return type;
    }
  };

  const getPaymentLogoByType: (type: SubscriptionPaymentType) => FileFromViews = (type: SubscriptionPaymentType): FileFromViews => {
    switch (type) {
      case SubscriptionPaymentType.Card:
        return FileFromViews.PaymentLogoCard;
      case SubscriptionPaymentType.ManuallyAccepted:
        return FileFromViews.PaymentLogoOnline;
      case SubscriptionPaymentType.Payu:
      case SubscriptionPaymentType.PayuPending:
        return FileFromViews.PaymentLogoPayu;
      case SubscriptionPaymentType.Przelewy24:
      case SubscriptionPaymentType.Przelewy24Pending:
        return FileFromViews.PaymentLogoPrzelewy24;
      case SubscriptionPaymentType.PosPayment:
      default:
        return FileFromViews.PaymentLogoPos; 
    }
  };

  const getParameterLabel: (parameterType: SubscriptionParameterType) => string = (parameterType: SubscriptionParameterType): string => {
    return parameters
      .find((item: SubscriptionStaticParameter): boolean => item.type === parameterType)
      ?.name ?? '';
  };

  const getParameterOptionLabel: (
    parameterType: SubscriptionParameterType, optionName: CyclicOrderPeriod | PosPaymentOptions
  ) => string = (
    parameterType: SubscriptionParameterType, optionName: CyclicOrderPeriod | PosPaymentOptions,
  ): string => {
    const parameterOptions: ParameterValue[] = parameters
      .find((item: SubscriptionStaticParameter): boolean => item.type === parameterType)
      ?.values ?? [];

    return parameterOptions.find((item: ParameterValue): boolean => item.code === optionName)?.name ?? '';
  };

  return { 
    getDeliveryNameByType, 
    getPaymentStatusByType, 
    getPaymentNameByType, 
    getDeliveryStatusByType, 
    getOrderStatusByType,
    getDeliveryLogoByType,
    orderStatuses,
    posList,
    targetingList,
    getParameterLabel,
    getParameterOptionLabel,
    getPaymentLogoByType,
  };
};
