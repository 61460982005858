import { AxiosResponse } from 'axios';

import { 
  ApiReject, 
  ApiResolve,
  ListPagination,
  SubscriptionParams,
  SubscriptionListElement,
  SubscriptionDetails,
} from '@chic/models';
import { api } from '../../api.service';
import { stringify } from 'query-string';
import {
  SetSubscriptionReplacementsRequest,
  SubscriptionReplacementProduct,
  SubscriptionSimulation,
  SubscriptionSimulationRequest,
  UpdateSubscriptionRequest,
} from '@chic/interfaces';
import { SubscriptionPaymentType } from '@chic/enums';

export const getSubscriptions: (params: SubscriptionParams) => Promise<ListPagination<SubscriptionListElement>> = (
  params: SubscriptionParams,
): Promise<ListPagination<SubscriptionListElement>> => new Promise(
  (resolve: ApiResolve<ListPagination<SubscriptionListElement>>, reject: ApiReject): Promise<void> => (
    api.get(`/management/subscriptions/?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ListPagination<SubscriptionListElement>>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSubscriptionDetails: (subscriptionId: number) => Promise<SubscriptionDetails> = (
  subscriptionId: number,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/subscriptions/${subscriptionId}`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const cancelSubscription: (subscriptionId: number) => Promise<SubscriptionDetails> = (
  subscriptionId: number,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/subscriptions/${subscriptionId}`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const simulateSubscription: (subscriptionId: number, data: SubscriptionSimulationRequest) => Promise<SubscriptionSimulation> = (
  subscriptionId: number, data: SubscriptionSimulationRequest,
): Promise<SubscriptionSimulation> => new Promise(
  (resolve: ApiResolve<SubscriptionSimulation>, reject: ApiReject): Promise<void> => (
    api.post(`/management/subscriptions/${subscriptionId}/simulation`, data)
      .then((response: AxiosResponse<SubscriptionSimulation>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const updateUserSubscription: (
  subscriptionId: number, data: UpdateSubscriptionRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: UpdateSubscriptionRequest,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.patch(`/management/subscriptions/${subscriptionId}`, data)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const manualConfirmSubscriptionDelivery: (
  subscriptionId: number, deliveryId: number
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, deliveryId: number,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post(`/management/subscriptions/${subscriptionId}/delivery/${deliveryId}/confirm`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const makeSubscriptionOrder: (subscriptionId: number) => Promise<SubscriptionDetails> = (
  subscriptionId: number,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post(`/management/subscriptions/${subscriptionId}/order`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const cancelUnpaidSubscriptionOrder: (
  subscriptionId: number, orderId: number
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, orderId: number,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/subscriptions/${subscriptionId}/order/${orderId}`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const createNewPaymentForSubscriptionOrder: (
  subscriptionId: number, orderId: number, paymentMethod: SubscriptionPaymentType | null
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, orderId: number, paymentMethod: SubscriptionPaymentType | null,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post(`/management/subscriptions/${subscriptionId}/create-payment/${orderId}`, { type: paymentMethod })
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const manualAcceptPaymentForSubscription: (
  subscriptionId: number, paymentId: number
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, paymentId: number,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post(`/management/subscriptions/${subscriptionId}/accept-payment/${paymentId}`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSubscriptionProductReplacements: (
  subscriptionId: number, orderId: number, productId: number
) => Promise<SubscriptionReplacementProduct[]> = (
  subscriptionId: number, orderId: number, productId: number,
): Promise<SubscriptionReplacementProduct[]> => new Promise(
  (resolve: ApiResolve<SubscriptionReplacementProduct[]>, reject: ApiReject): Promise<void> => (
    api.get(`/management/subscriptions/${subscriptionId}/products/replacements?${stringify(
      { orderId, productId }, { skipEmptyString: true },
    )}`)
      .then((response: AxiosResponse<SubscriptionReplacementProduct[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const setSubscriptionProductReplacements: (
  subscriptionId: number, data: SetSubscriptionReplacementsRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: SetSubscriptionReplacementsRequest,
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post(`/management/subscriptions/${subscriptionId}/products/replacements`, data)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);
