import { Breakpoint, Color, FontWeight, hexToRgba, StyledComponent, TransactionProductBox } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const StatusBox: StyledComponent<'div'> = styled.div`
  padding: 24px 40px;
  border: 1px solid ${Color.ICBlack100};
  background-color: ${hexToRgba(Color.ICBlack400, 0.4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 24px 20px;
  }
`;

export const OrderId: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  font-weight: ${FontWeight.Bold};
  line-height: 18px;
  color: ${Color.ICYellow100};
  flex-shrink: 0;

  @media ${Breakpoint.Mobile} {
    padding-bottom: 20px;
    border-bottom: 1px solid ${Color.ICBlack100};
    width: 100%;
  }
`;

export const Section: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;
  border-top: 1px solid ${Color.ICGray500};
`;

export const ChoiceBoxBanners: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 20px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 24px;
  }
`;

export const Products: StyledComponent<'div'> = styled.div``;
