import React, { Dispatch, useReducer, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { SortOrder, RoutingPath, QueryKey } from '@chic/enums';

import {
  Container,
  InnerContainer,
  PageTitleWrapper,
  FiltersContainer,
  StyledInput,
  StyledPagination,
  TableWrapper,
  TableChildren,
  StyledMenuPosition,
  EmptyListMessage,
} from './userRoleVerificationList.styled';
import { getUsersToVerification } from '@chic/api';
import { UserToVerification, VerificationList } from '@chic/models';
import { UserRoleVerificationListAction, UserRoleVerificationListState } from './userRoleVerificationList.types';
import { userRoleVerificationListReducer } from './userRoleVerificationList.reducer';
import { userRoleVerificationListInitialState } from './userRoleVerificationList.const';
import { UserRoleVerificationListActions, UserVerificationTableName } from './userRoleVerificationList.enum';
import { useTitle } from '@chic/hooks';
import { 
  Color, 
  ComponentColorTheme, 
  DetailsTable, 
  DetailsRowTheme, 
  DetailsTableCellType, 
  DetailsTableRow,  
  Icon, 
  IconName, 
  PageTitle, 
  PageTitleSize,
  PaginationDetails,
  UseState,
  VisibilitySettings,
  usePagination, 
} from '@chic-loyalty/ui';
import { QueryObserverResult, useQuery } from 'react-query';
import { RoleVerificationBox } from '@chic/components';

export const UserRoleVerificationListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination();
  const [usersVerificationDetailsTable, setUserVerificationDetailsTable]: UseState<DetailsTableRow[] | null> 
    = useState<DetailsTableRow[] | null>(null);
  const [state, dispatch]: [UserRoleVerificationListState, Dispatch<UserRoleVerificationListAction>] 
    = useReducer(userRoleVerificationListReducer, userRoleVerificationListInitialState);
  useTitle(t('chic.crmApp.userVerificationList.title'));

  const { isFetched }: QueryObserverResult = useQuery(
    [QueryKey.UsersVerificationList, state, page, itemsPerPage, offset],
    (): Promise<VerificationList> => getUsersToVerification({
      sortBy: state.sortKey ? `${state.sortKey}.${state.sortOrder}` : undefined,
      crmId: state.filterCrmIdValue,
      fullname: state.filterNameValue,
      email: state.filterEmailValue,
      phone: state.filterPhoneValue,
      page: page,
      limit: itemsPerPage,
      offset,
    }),
    {
      onSuccess: (data: VerificationList): void => {
        setMaxItems(data.count);
        setUserVerificationDetailsTable([
          ...data.elements.map((element: UserToVerification): DetailsTableRow => ({
            theme: DetailsRowTheme.Normal,
            cells: [
              {
                name: UserVerificationTableName.CrmId,
                value: String(element.crmId),
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: UserVerificationTableName.FullName,
                value: `${element.name} ${element.surname}`,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: UserVerificationTableName.Email,
                value: element.email ?? '-',
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: UserVerificationTableName.Phone,
                value: element.phone ?? '-',
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: UserVerificationTableName.Verification,
                cellType: DetailsTableCellType.Children,
                visibilitySettings: VisibilitySettings.Always,
                customAttributes: {
                  children: (
                    <TableChildren>
                      <RoleVerificationBox 
                        userName={`${element.name} ${element.surname}`} 
                        userId={element.id}
                      />
                      <StyledMenuPosition internalPath={RoutingPath.UserRoleVerification.replace(':userId', element.id.toString())}>
                        <Icon name={IconName.ArrowRight} color={Color.ICBlue} size={24} />
                      </StyledMenuPosition>
                    </TableChildren>
                  ),
                },
              },
            ],
          })),
        ]);
      },
      onError: (): void => undefined,
    },
  );

  const handleSortAction: (columnName: UserVerificationTableName) => void = (
    columnName: UserVerificationTableName,
  ): void => {
    dispatch({ type: UserRoleVerificationListActions.SetSortKey, payload: columnName });
    if (state.sortOrder === undefined || state.sortKey !== columnName) {
      dispatch({ type: UserRoleVerificationListActions.SetSortOrder, payload: SortOrder.Ascending });
    } else {
      dispatch({
        type: UserRoleVerificationListActions.SetSortOrder,
        payload: state.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
      });
    }
  };

  const handleCrmIdFilterAction: (value: string) => void = (value: string): void => {
    dispatch({ type: UserRoleVerificationListActions.SetFilterCrmIdValue, payload: value });
  };

  const handleNameFilterAction: (value: string) => void = (value: string): void => {
    dispatch({ type: UserRoleVerificationListActions.SetFilterNameValue, payload: value });
  };

  const handleEmailFilterAction: (value: string) => void = (value: string): void => {
    dispatch({ type: UserRoleVerificationListActions.SetFilterEmailValue, payload: value });
  };

  const handlePhoneFilterAction: (value: string) => void = (value: string): void => {
    dispatch({ type: UserRoleVerificationListActions.SetFilterPhoneValue, payload: value });
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle size={PageTitleSize.Big} label={t('chic.crmApp.userVerificationList.pageTitle')} />
      </PageTitleWrapper>
      <InnerContainer>
        <FiltersContainer>
          <StyledInput
            placeholder={t('chic.crmApp.userVerificationList.filters.crmId.placeholder')}
            onChange={handleCrmIdFilterAction}
          />
          <StyledInput
            placeholder={t('chic.crmApp.userVerificationList.filters.name.placeholder')}
            onChange={handleNameFilterAction}
          />
          <StyledInput
            placeholder={t('chic.crmApp.userVerificationList.filters.email.placeholder')}
            onChange={handleEmailFilterAction} 
          />
          <StyledInput
            placeholder={t('chic.crmApp.userVerificationList.filters.phone.placeholder')}
            onChange={handlePhoneFilterAction}
          />
        </FiltersContainer>
        {usersVerificationDetailsTable !== null && isFetched && (
          usersVerificationDetailsTable.length ? (
            <TableWrapper>
              <DetailsTable 
                headers={[{
                  name: UserVerificationTableName.CrmId,
                  label: t('chic.crmApp.userVerificationList.usersVerificationDetailsTable.crmId.header'),
                  onSortClick: (columnName: string): void => handleSortAction(columnName as UserVerificationTableName),
                }, {
                  name: UserVerificationTableName.FullName,
                  label: t('chic.crmApp.userVerificationList.usersVerificationDetailsTable.fullName.header'),
                  onSortClick: (columnName: string): void => handleSortAction(columnName as UserVerificationTableName),
                }, {
                  name: UserVerificationTableName.Email,
                  label: t('chic.crmApp.userVerificationList.usersVerificationDetailsTable.email.header'),
                  onSortClick: (columnName: string): void => handleSortAction(columnName as UserVerificationTableName),
                }, {
                  name: UserVerificationTableName.Phone,
                  label: t('chic.crmApp.userVerificationList.usersVerificationDetailsTable.phone.header'),
                  onSortClick: (columnName: string): void => handleSortAction(columnName as UserVerificationTableName),
                }, {
                  name: UserVerificationTableName.Verification,
                  label: t('chic.crmApp.userVerificationList.usersVerificationDetailsTable.verification.header'),
                }]}
                tableData={usersVerificationDetailsTable}
                columnsVisibleOnMobile={[UserVerificationTableName.CrmId, UserVerificationTableName.FullName]}
              />
              <StyledPagination 
                activePage={page} 
                pagesCount={pagesCount} 
                onActivePageChange={setPage} 
                colorTheme={ComponentColorTheme.IC} 
              />
            </TableWrapper>
          ) : (
            <EmptyListMessage>{t('chic.crmApp.userVerificationList.emptyList')}</EmptyListMessage>
          )
        )}
      </InnerContainer>
    </Container>
  );
};
