import { TransProps, useTranslation } from 'react-i18next';
import {
  AddFileInitialData,
  AddFileSettings, 
  ButtonBannerSettings, 
  DropdownOption, 
  FileSettingsBox, 
  ItemPickerItemDetails, 
  useNotifications, 
  UseNotifications, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { 
  createNewAsset, 
  createNewScreen, 
  createNewSetup, 
  editAsset, 
  editScreen, 
  editSetup, 
  getAssetDetails, 
  getScreenDetails, 
  getSetupDetails, 
  uploadAsset, 
} from '@chic/api';
import { 
  AdAssetPropertyType, 
  AdNodeType, 
  AdPosType, 
  AdScreenType, 
  AdsTargetType, 
  AnimationStateType, 
  FileFromViews, 
  FileType, 
  FileTypeExtended, 
  QueryKey, 
  RoutingPath, 
  SetupType, 
  UploadAssetType, 
} from '@chic/enums';
import { 
  UploadedAssetDetails, 
  FrontendApiError, 
  UseAnimations,
  AssetDetails, 
  UseStatics, 
  AdsStaticDefinition, 
  AdsNode,
  ScreenDetails,
  ScreenObject,
  ScreenNodeDetails,
  SetupDetails,
  ScreensaverDetails,
  TargetDetails,
  UseAnimationsContext,
  AssetProperty,
} from '@chic/models';
import { useEffect, useMemo, useState } from 'react';
import { useStatics } from './useStatics.hook';
import { useQuery } from 'react-query';
import { useAnimationsContext } from './useAnimationsContext.hook';
import { isSetupType } from '@chic/guards';
import { stringify } from 'query-string';

export const useAnimations: (
  setupType: SetupType,
  assetType: UploadAssetType,
  screenType?: AdScreenType,
  setupId?: string,
  screenId?: string,
) => UseAnimations = (
  setupType: SetupType,
  assetType: UploadAssetType,
  screenType?: AdScreenType,
  setupId?: string,
  screenId?: string,
): UseAnimations => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { posList, targetingList }: UseStatics = useStatics();
  const { redirect }: UseRedirect = useRedirect();
  const { animationData, saveAnimationStateValues, clearSessionStorage }: UseAnimationsContext = useAnimationsContext();
  const [animationName, setAnimationName]: UseState<string> = useState<string>('');
  const [uploadedFile, setUploadedFile]: UseState<UploadedAssetDetails | null> = useState<UploadedAssetDetails | null>(null);
  const [savedFiles, setSavedFiles]: UseState<AssetDetails[]> = useState<AssetDetails[]>([]);
  const [selectedTargets, setSelectedTargets]: UseState<ItemPickerItemDetails[]> = useState<ItemPickerItemDetails[]>([]);
  const [screens, setScreens]: UseState<ScreenObject[]> = useState<ScreenObject[]>([]);
  const [fileToEdit, setFileToEdit]: UseState<AssetDetails | null> = useState<AssetDetails | null>(null);
  const [resetUploadedData, setResetUploadedData]: UseState<boolean> = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage]: UseState<string> = useState<string>('');

  useEffect(
    (): void => {
      saveAnimationStateValues({ type: setupId || screenId ? AnimationStateType.Edit : AnimationStateType.Create });
    },
    [setupId, screenId],
  );

  useEffect(
    (): void => {
      if (!isSetupType(setupType)) {
        redirect(RoutingPath.AdsList);
      }
    },
    [setupType],
  );

  useEffect(
    (): void => {
      if (animationData && !screenType) {
        if (!animationName && !!animationData.animationName) {
          setAnimationName(animationData.animationName);
        }
        
        if (!savedFiles.length && !!animationData.savedFiles?.length) {
          setSavedFiles(animationData.savedFiles);
        }

        if (!screens.length && !!animationData.screens) {
          setScreens(animationData.screens);
        }

        if (!selectedTargets.length && !!animationData.targets) {
          setSelectedTargets(animationData.targets.filter((target: ItemPickerItemDetails): boolean => !!target.name));
        }
      }
    },
    [animationData],
  );

  useQuery(
    [QueryKey.SetupDetails, posList, targetingList],
    (): Promise<SetupDetails> => getSetupDetails(setupId ?? ''),
    {
      enabled: !!setupId,
      onSuccess: (data: SetupDetails): void => {
        const screensFromEdit: ScreenObject[] = animationData.screens ?? data.screens.map((screen: ScreenDetails): ScreenObject => ({
          id: screen.id,
          name: screen.name,
          type: screen.type,
        }));

        const savedFilesFromDetails: AssetDetails[] = [];
        setSavedFiles(data.screensaver.map((screensaver: ScreensaverDetails): AssetDetails => ({
          type: screensaver.type,
          id: screensaver.id,
          name: screensaver.name,
          path: screensaver.path,
          properties: [{
            type: AdAssetPropertyType.Duration,
            value: String(screensaver.duration),
          }],
          fileInfo: null,
        })));
        setScreens(screensFromEdit);
        setAnimationName(data.name);
        const posTargets: ItemPickerItemDetails[] = data.targeting
          .filter((target: TargetDetails): boolean => target.type === AdsTargetType.SelectedStore && !!target.value)
          .map((target: TargetDetails): ItemPickerItemDetails => {
            const foundPos: AdsStaticDefinition<AdPosType> | undefined = posList
              .find((pos: AdsStaticDefinition<AdPosType>): boolean => pos.id === target.value);
            return {
              name: foundPos?.id ?? foundPos?.label ?? '',
              type: target.type,
              label: foundPos?.label ?? '',
            };
          });
        const selectedStoreTarget: AdsStaticDefinition<AdsTargetType> | undefined = targetingList
          .find((targeting: AdsStaticDefinition<AdsTargetType>): boolean => targeting.type === AdsTargetType.SelectedStore);
        const targets: ItemPickerItemDetails[] = [
          ...data.targeting
            .filter((target: TargetDetails): boolean => target.type && !target.value)
            .map((target: TargetDetails): ItemPickerItemDetails => {
              const foundTarget: AdsStaticDefinition<AdsTargetType> | undefined = targetingList
                .find((targeting: AdsStaticDefinition<AdsTargetType>): boolean => targeting.type === target.type);
              return {
                name: foundTarget?.id ?? foundTarget?.label ?? '',
                type: foundTarget?.type ?? '',
                label: foundTarget?.label ?? '',
                isMainTarget: true,
              };
            }),
          ...(selectedStoreTarget && !!posTargets.length ? [{
            name: selectedStoreTarget.type,
            type: selectedStoreTarget.type,
            label: selectedStoreTarget.label,
            isMainTarget: true,
            withOptions: true,
          }] : []),
        ];
        setSelectedTargets([ ...targets, ...posTargets ].filter((target: ItemPickerItemDetails): boolean => !!target.name));
        saveAnimationStateValues({
          setupId,
          type: AnimationStateType.Edit,
          savedFiles: savedFilesFromDetails,
          screens: screensFromEdit,
          targets: [ ...targets, ...posTargets ],
        });
      },
    },
  );

  useQuery(
    [QueryKey.ScreenDetails],
    (): Promise<ScreenDetails> => getScreenDetails(screenId ?? ''),
    {
      enabled: !!screenId,
      onSuccess: (data: ScreenDetails): void => {
        setAnimationName(data.name);
        setSavedFiles(data.nodes.map((node: ScreenNodeDetails): AssetDetails => ({
          type: node.type,
          id: node.id,
          name: 'unknown',
          path: node.data.assetPath,
          properties: [],
          fileInfo: null,
        })));
      },
    },
  );

  const onSavedFilesChangePositions: (items: FileSettingsBox[]) => void = (items: FileSettingsBox[]): void => {
    const sortedSavedFiles: AssetDetails[] = savedFiles.sort((a: AssetDetails, b: AssetDetails): number => {
      const indexA: number = items.findIndex((item: FileSettingsBox) => item.path === a.path);
      const indexB: number = items.findIndex((item: FileSettingsBox) => item.path === b.path);
      return indexA - indexB;
    });
    
    setSavedFiles(sortedSavedFiles);
    if (!screenType) {
      saveAnimationStateValues({ savedFiles: sortedSavedFiles });
    }
  };

  const onDeleteSavedFile: (items: FileSettingsBox[]) => void = (items: FileSettingsBox[]): void => {
    const sortedSavedFiles: AssetDetails[] = savedFiles.filter((file: AssetDetails): boolean => {
      return items.some((item: FileSettingsBox): boolean => {
        if (item.id) {
          return item.id === file.id;
        } else {
          return item.path === file.path;
        }
      });
    });
    
    setSavedFiles(sortedSavedFiles);
    if (!screenType) {
      saveAnimationStateValues({ savedFiles: sortedSavedFiles });
    }
  };

  const onAddFile: (file: File) => Promise<void> = (file: File): Promise<void> => {
    setUploadErrorMessage('');
    return new Promise((resolve: () => void, reject: () => void): void => {
      uploadAsset({ 
        file, 
        type: assetType,
      })
        .then((data: UploadedAssetDetails): void => {
          setUploadedFile(data);
          setResetUploadedData(false);
          resolve();
          addToast({ content: t('chic.management.useAnimations.onAddFile.success') });
        })
        .catch((error: FrontendApiError): void => {
          setUploadErrorMessage(error.firstErrorTranslated ?? t('chic.management.useAnimations.onAddFile.error'));
          addToast({ content: t('chic.management.useAnimations.onAddFile.error') });
          reject();
        });
    });
  };

  const onSaveFile: (fileSettings: AddFileSettings) => Promise<void> = (fileSettings: AddFileSettings): Promise<void> => {
    return new Promise((resolve: () => void): void => {
      if (fileToEdit) {
        const duration: string | undefined = fileSettings.duration !== undefined
          ? String(fileSettings.duration)
          : fileToEdit.type === FileTypeExtended.Video && fileToEdit.fileInfo?.originalDuration
            ? String(fileToEdit.fileInfo.originalDuration)
            : undefined;

        editAsset(fileToEdit.id, {
          type: fileToEdit.type as unknown as FileType,
          name: fileToEdit.name,
          path: fileToEdit.path,
          properties: [
            ...(duration ? [{
              type: AdAssetPropertyType.Duration,
              value: duration,
            }] : []),
            ...(fileSettings.target ? [{
              type: AdAssetPropertyType.Target,
              value: fileSettings.target,
            }] : []),
          ],
        })
          .then((data: AssetDetails): void => {
            const newSavedFiles: AssetDetails[] = savedFiles
              .map((savedFile: AssetDetails) => savedFile.id === data.id ? data : savedFile);
            setSavedFiles(newSavedFiles);
            if (!screenType) {
              saveAnimationStateValues({ savedFiles: newSavedFiles });
            }
            setFileToEdit(null);
            resolve();
            addToast({ content: t('chic.management.useAnimations.onSaveFile.success') });
          })
          .catch((error: FrontendApiError): void => {
            addToast({ content: error.message ?? t('chic.management.useAnimations.onSaveFile.error') });
          });
      } else if (uploadedFile) {
        createNewAsset({
          type: uploadedFile.type,
          name: uploadedFile.originalFilename,
          path: uploadedFile.path,
          id: null,
          properties: [
            {
              type: AdAssetPropertyType.Duration,
              value: fileSettings.duration !== undefined
                ? String(fileSettings.duration)
                : String(uploadedFile.duration),
            },
            ...(fileSettings.target ? [{
              type: AdAssetPropertyType.Target,
              value: fileSettings.target,
            }] : []),
          ],
        })
          .then((data: AssetDetails): void => {
            setSavedFiles([ ...savedFiles, data ]);
            if (!screenType) {
              saveAnimationStateValues({ savedFiles: [ ...savedFiles, data ] });
            }
            resolve();
            addToast({ content: t('chic.management.useAnimations.onSaveFile.success') });
          })
          .catch((error: FrontendApiError): void => {
            addToast({ content: error.message ?? t('chic.management.useAnimations.onSaveFile.error') });
          });
      }
    });
  };

  const onSetAnimationName: (value: string) => void = (value: string): void => {
    setAnimationName(value);
    if (!screenType) {
      saveAnimationStateValues({ animationName: value });
    }
  };

  const requirements: string[] = useMemo(
    (): string[] => {
      switch (assetType) {
        case UploadAssetType.TVAnimation:
          return [
            t('chic.management.useAnimations.requirements.tvAnimation.formats'),
            t('chic.management.useAnimations.requirements.tvAnimation.maxSize'),
            t('chic.management.useAnimations.requirements.tvAnimation.resolution'),
          ];
        case UploadAssetType.TVButton: 
          return [
            t('chic.management.useAnimations.requirements.tvButton.formats'),
            t('chic.management.useAnimations.requirements.tvButton.maxSize'),
            t('chic.management.useAnimations.requirements.tvButton.resolution'),
          ];
        case UploadAssetType.TabletAnimation:
        default: 
          return [
            t('chic.management.useAnimations.requirements.tabletAnimation.formats'),
            t('chic.management.useAnimations.requirements.tabletAnimation.maxSize'),
            t('chic.management.useAnimations.requirements.tabletAnimation.resolution'),
          ];
      }
    },
    [assetType],
  );

  const acceptedFilesTypes: Record<string, string[]> = useMemo(
    (): Record<string, string[]> => {
      switch (assetType) {
        case UploadAssetType.TVButton: 
          return {
            ['image/jpeg']: [],
            ['image/png']: [],
          };
        case UploadAssetType.TabletAnimation:
        case UploadAssetType.TVAnimation:
        default:
          return {
            ['image/jpeg']: [],
            ['image/png']: [],
            ['video/mp4']: [],
          };
      }
    },
    [assetType],
  );

  const onStateReset: () => void = (): void => {
    setUploadedFile(null);
    setSavedFiles([]);
    setAnimationName('');
    setSelectedTargets([]);
    clearSessionStorage();
    setResetUploadedData(true);
    window.scrollTo({ top: 0 });
  };

  const targetOptions: ItemPickerItemDetails[] = useMemo(
    (): ItemPickerItemDetails[] => {
      if (!targetingList.length || !posList.length) {
        return [];
      }

      return [
        ...targetingList.map((targeting: AdsStaticDefinition<AdsTargetType>): ItemPickerItemDetails => ({
          name: targeting.id ?? targeting.label,
          label: targeting.label,
          type: targeting.type,
          withOptions: targeting.type === AdsTargetType.SelectedStore,
          isMainTarget: true,
        })),
        ...posList.map((pos: AdsStaticDefinition<AdPosType>): ItemPickerItemDetails => ({
          name: pos.id ?? pos.label,
          label: pos.label,
          type: AdsTargetType.SelectedStore,
        })),
      ];
    },
    [targetingList, posList],
  );

  const onCreateNewSetup: () => void = (): void => {
    const filteredTargets: ItemPickerItemDetails[] = selectedTargets
      .filter((selectedTarget: ItemPickerItemDetails): boolean => !(
        selectedTarget.type === AdsTargetType.SelectedStore && selectedTarget.type === selectedTarget.name
      ));

    createNewSetup({
      type: setupType,
      name: animationName,
      screensaver: savedFiles.map((savedFile: AssetDetails): string => savedFile.id),
      screens: screens.map((screen: ScreenObject): string => screen.id),
      targeting: filteredTargets.map((selectedTarget: ItemPickerItemDetails): TargetDetails => ({
        type: selectedTarget.type as AdsTargetType,
        value: selectedTarget.type === AdsTargetType.SelectedStore ? selectedTarget.name : null,
      })),
    })
      .then((): void => {
        redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`);
        addToast({ content: t('chic.management.useAnimations.onCreateNewSetup.success') });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: error.message ?? t('chic.management.useAnimations.onCreateNewSetup.error') });
      });
  };

  const onEditSetup: () => void = (): void => {
    if (!setupId) {
      return;
    }

    const filteredTargets: ItemPickerItemDetails[] = selectedTargets
      .filter((selectedTarget: ItemPickerItemDetails): boolean => !(
        selectedTarget.type === AdsTargetType.SelectedStore && selectedTarget.type === selectedTarget.name
      ));

    editSetup(setupId, {
      type: setupType,
      name: animationName,
      screensaver: savedFiles.map((savedFile: AssetDetails): string => savedFile.id),
      screens: screens.map((screen: ScreenObject): string => screen.id),
      targeting: filteredTargets.map((selectedTarget: ItemPickerItemDetails): TargetDetails => ({
        type: selectedTarget.type as AdsTargetType,
        value: selectedTarget.type === AdsTargetType.SelectedStore ? selectedTarget.name : null,
      })),
      active: true,
    })
      .then((): void => {
        redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`);
        addToast({ content: t('chic.management.useAnimations.onEditSetup.success') });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: error.message ?? t('chic.management.useAnimations.onEditSetup.error') });
      });
  };

  const onCreateNewScreen: () => void = (): void => {
    if (!screenType) {
      return;
    }

    createNewScreen({
      type: screenType,
      name: animationName,
      nodes: savedFiles.map((savedFile: AssetDetails): AdsNode => ({
        id: savedFile.id,
        type: AdNodeType.Asset,
      })),
      id: null,
    })
      .then((screenDetails: ScreenDetails): void => {
        saveAnimationStateValues({
          screens: [
            ...animationData.screens ?? [],
            {
              id: screenDetails.id,
              name: screenDetails.name,
              type: screenType,
            },
          ],
        });
        if (animationData.setupId) {
          redirect(RoutingPath.AdEdit, { type: setupType, id: animationData.setupId ?? '' });
        } else {
          redirect(RoutingPath.AdAdd, { type: setupType });
        }
        addToast({ content: t('chic.management.useAnimations.onCreateNewScreen.success') });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: error.message ?? t('chic.management.useAnimations.onCreateNewScreen.error') });
      });
  };

  const onEditScreen: () => void = (): void => {
    if (!screenType || !screenId) {
      return;
    }

    editScreen(screenId, {
      type: screenType,
      name: animationName,
      nodes: savedFiles.map((savedFile: AssetDetails): AdsNode => ({
        id: savedFile.id,
        type: AdNodeType.Asset,
      })),
    })
      .then((screenDetails: ScreenDetails): void => {
        saveAnimationStateValues({
          screens: animationData.screens ? [
            ...animationData.screens.map((screenObject: ScreenObject): ScreenObject => {
              if (screenDetails.id === screenObject.id) {
                return {
                  id: screenDetails.id,
                  name: screenDetails.name,
                  type: screenType,
                };
              } else {
                return screenObject;
              }
            }),
          ] : [],
        });
        if (animationData.type === AnimationStateType.Edit) {
          redirect(RoutingPath.AdEdit, { type: setupType, id: animationData.setupId ?? '' });
        } else {
          redirect(RoutingPath.AdAdd, { type: setupType });
        }
        addToast({ content: t('chic.management.useAnimations.onEditScreen.success') });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: error.message ?? t('chic.management.useAnimations.onEditScreen.error') });
      });
  };

  const onChangeSelectedTargets: (options: ItemPickerItemDetails[]) => void = (options: ItemPickerItemDetails[]): void => {
    const selectedStoreTarget: AdsStaticDefinition<AdsTargetType> | undefined = targetingList
      .find((targeting: AdsStaticDefinition<AdsTargetType>): boolean => targeting.type === AdsTargetType.SelectedStore);
    const optionsCopy: ItemPickerItemDetails[] = [
      ...options,
      ...(!!selectedStoreTarget && options
        .find((option: ItemPickerItemDetails): boolean => option.type === AdsTargetType.SelectedStore && !option.isMainTarget)
        ? [{
          name: selectedStoreTarget.type,
          type: selectedStoreTarget.type,
          label: selectedStoreTarget.label,
          isMainTarget: true,
          withOptions: true,
        }] 
        : []
      ),
    ];
    setSelectedTargets(optionsCopy);
    if (!screenType) {
      saveAnimationStateValues({ targets: optionsCopy });
    }
  };

  const onEditFile: (item: FileSettingsBox) => void = (item: FileSettingsBox): void => {
    const searchedSavedFile: AssetDetails | null = savedFiles
      .find((savedFile: AssetDetails): boolean => item.id
        ? item.id === savedFile.id
        : item.name === savedFile.name,
      ) ?? null;

    if (searchedSavedFile?.fileInfo) {
      setFileToEdit(searchedSavedFile);
    } else if (searchedSavedFile?.id) {
      getAssetDetails(searchedSavedFile?.id)
        .then(setFileToEdit)
        .catch((): void => undefined);
    }
  };

  const onAddNewItem: () => void = (): void => {
    setResetUploadedData(true);
  };

  const targetsForButtons: DropdownOption[] | undefined = useMemo(
    (): DropdownOption[] | undefined => {
      if (animationData.screens?.length) {
        return animationData.screens
          .filter((screen: ScreenObject): boolean => screen.type === AdScreenType.AnimationsSlider)
          .map((screen: ScreenObject): DropdownOption => ({
            name: screen.id,
            label: screen.name,
          }));
      } else if (screens.length) {
        return screens
          .filter((screen: ScreenObject): boolean => screen.type === AdScreenType.AnimationsSlider)
          .map((screen: ScreenObject): DropdownOption => ({
            name: screen.id,
            label: screen.name,
          }));
      }
    },
    [animationData, screens],
  );

  const buttonBanners: ButtonBannerSettings[] | undefined = useMemo(
    (): ButtonBannerSettings[] | undefined => {
      return setupType === SetupType.Tv ? [
        {
          title: t('chic.management.useAnimations.buttonBanner.animationsSlider.title'),
          description: t('chic.management.useAnimations.buttonBanner.animationsSlider.description'),
          buttonSettings: {
            label: t('chic.management.useAnimations.buttonBanner.animationsSlider.buttonLabel'),
            action: (): void => redirect(RoutingPath.AdAddScreen, { type: setupType, screenType: AdScreenType.AnimationsSlider }),
          },
          image: FileFromViews.UseAnimationsBannerSlider,
        },
        {
          title: t('chic.management.useAnimations.buttonBanner.categoriesList.title'),
          description: t('chic.management.useAnimations.buttonBanner.categoriesList.description'),
          buttonSettings: {
            label: t('chic.management.useAnimations.buttonBanner.categoriesList.buttonLabel'),
            action: (): void => redirect(RoutingPath.AdAddScreen, { type: setupType, screenType: AdScreenType.CategoriesList }),
          },
          image: FileFromViews.UseAnimationsBannerCategories,
          isDisabled: !targetsForButtons?.length,
        },
      ] : undefined;
    },
    [targetsForButtons, setupType],
  );

  const onDeleteScreen: (id: string) => void = (id: string): void => {
    const filteredScreens: ScreenObject[] = screens.filter((screen: ScreenObject): boolean => screen.id !== id);
    saveAnimationStateValues({ screens: filteredScreens });
    setScreens(filteredScreens);
  };

  const transformAssetDetailsToAddFileInitial: (file: AssetDetails | null) => AddFileInitialData | undefined = (
    file: AssetDetails | null,
  ): AddFileInitialData | undefined => {
    if (!file) {
      return undefined;
    }

    return {
      path: file.path,
      name: file.name,
      format: file.type,
      size: file.fileInfo?.size.formatted ?? t('chic.management.global.noData'),
      duration: parseInt(file.properties
        .find((property: AssetProperty): boolean => property.type === AdAssetPropertyType.Duration)?.value ?? ''),
      isVideo: file.type === FileTypeExtended.Video,
    };
  };

  return { 
    onAddFile, 
    onSaveFile, 
    onSetAnimationName, 
    animationName, 
    requirements, 
    savedFiles, 
    selectedTargets,
    onCreateNewSetup,
    targetOptions,
    onStateReset,
    onCreateNewScreen,
    screens,
    acceptedFilesTypes,
    onSavedFilesChangePositions,
    onEditScreen,
    onDeleteSavedFile,
    onEditSetup,
    onChangeSelectedTargets,
    onEditFile,
    fileToEdit,
    onAddNewItem,
    resetUploadedData,
    buttonBanners,
    targetsForButtons,
    onDeleteScreen,
    setupType,
    transformAssetDetailsToAddFileInitial,
    uploadErrorMessage,
  };
};
