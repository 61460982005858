export enum ConfigKey {
  MgmInvitation = 'mgm_invitation',
  MgmConfirm = 'mgm_confirm',
  PickupPoint = 'pickupPoint',
  Subscriptions = 'subscriptions',
  SubscriptionsCreate = 'subscriptionsCreate',
  EscLoyaltyProgramLimited = 'escLoyaltyProgramLimited',
  EscLoyaltyProgramDisabled = 'escLoyaltyProgramDisabled',
  SubscriptionParameterPosPaymentEnabled = 'subscriptionParameterPosPaymentEnabled',
  SubscriptionParameterCyclicOrderEnabled = 'subscriptionParameterCyclicOrderEnabled',
  CustomerProfileEditEnabled = 'customerProfileEditEnabled',
  CustomerEmailRecoveryEnabled = 'customerEmailRecoveryEnabled',
}
