import { Breakpoint, CalcSize, Color, Grid, InfoBox, StyledComponent, TransactionProductBox } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('${FileFromViews.SubscriptionOrderViewBackground}');
  background-size: 100% 100vh;
  background-position: top right;
  background-repeat: no-repeat;
  
  @media ${Breakpoint.SmallMobile} {
    background: url('${FileFromViews.SubscriptionOrderViewBackgroundMobile}');
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICBlack100};
    background-repeat: no-repeat;
    background-size: 100% 100vh;
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px 24px 0;
  
  @media ${Breakpoint.Mobile} {
    padding: 30px 24px;
    border-bottom: 1px solid ${Color.ICBlack100};
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  width: 100%;
  padding: 0 24px;
  margin: 62px 0;

  @media ${Breakpoint.Mobile} {
    margin: 20px 0;
  }
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 40px;
`;

export const DetailsEditBoxes: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 12px;

  @media ${Breakpoint.CustomDesktop} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ProductsBox: StyledComponent<'div'> = styled.div`
  margin: 16px 0 40px;
  border-top: 1px solid ${Color.ICGray500};
  border-bottom: 1px solid ${Color.ICGray500};
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 20px 0;
  
  & + & {
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const ButtonsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 16px;
    padding: 28px 20px;
  }
`;

export const OrderSummaryContainer: StyledComponent<'div'> = styled.div`
  margin-top: 16px;
`;

export const StyledInfoBox: StyledComponent<typeof InfoBox> = styled(InfoBox)`
  margin-bottom: 40px;
`;
