import { UseState, MapPointDelivery, OpeningDetails, UseNotifications, useNotifications, OpeningDayNo } from '@chic-loyalty/ui';
import { QueryKey, SubscriptionPickupPointType } from '@chic/enums';
import { FrontendApiError, PickupPointDetails, PickupPointOpeningHours, PickupPointsListRequest } from '@chic/models';
import { RefObject, useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { DebouncedState, useDebounce } from 'use-debounce';
import { getPickupPointsList } from '@chic/api';
import { isPickupPointType } from '@chic/utils';
import { UseSubscriptionEditDeliveryPoint } from './subscriptionEditDeliveryPoint.types';

export const useSubscriptionEditDeliveryPoint: (
  listRef: RefObject<HTMLDivElement>,
  selectedDelivery: SubscriptionPickupPointType | null,
  pointId?: string,
) => UseSubscriptionEditDeliveryPoint = (
  listRef: RefObject<HTMLDivElement>,
  selectedDelivery: SubscriptionPickupPointType | null,
  pointId: string = '',
): UseSubscriptionEditDeliveryPoint => {
  const { addToast }: UseNotifications = useNotifications();
  const [mapPoints, setMapPoints]: UseState<MapPointDelivery[]> = useState<MapPointDelivery[]>([]);
  const [pickupPoints, setPickupPoints]: UseState<PickupPointDetails[]> = useState<PickupPointDetails[]>([]);
  const [shouldFlyToPoint, setShouldFlyToPoint]: UseState<boolean> = useState<boolean>(true);
  const [inputValue, setInputValue]: UseState<string> = useState<string>('');
  const [activePointId, setActivePointId]: UseState<number | null> = useState<number | null>(null);
  const [pointDetails, setPointDetails]: UseState<PickupPointDetails | null> = useState<PickupPointDetails | null>(null);
  const [currentPosition, setCurrentPosition]: UseState<[number, number] | null> = useState<[number, number] | null>(null);
  const [pointPosition, setPointPosition]: UseState<[number, number] | null> = useState<[number, number] | null>([52, 20]);
  const [debouncedInputValue]: [string, DebouncedState<(value: string) => void>] = useDebounce(inputValue, 500);

  const activePoint: MapPointDelivery | undefined = useMemo(
    (): MapPointDelivery | undefined => (
      mapPoints.find((point: MapPointDelivery): boolean => point.id === activePointId)
    ),
    [activePointId, mapPoints],
  );

  const selectedDeliveryType: SubscriptionPickupPointType | undefined = useMemo(
    (): SubscriptionPickupPointType | undefined => {
      return isPickupPointType(selectedDelivery) ? selectedDelivery : undefined;
    },
    [selectedDelivery],
  );

  const requestParams: Partial<PickupPointsListRequest> = useMemo(
    (): Partial<PickupPointsListRequest> => (
      {
        location: pointPosition ? `${pointPosition[0]} ${pointPosition[1]}` : undefined,
        text: !pointPosition ? debouncedInputValue : undefined,
        type: selectedDeliveryType,
      }
    ),
    [pointPosition, debouncedInputValue, selectedDeliveryType],
  );

  useQuery(
    [QueryKey.PickupPointsList, requestParams, pointId],
    (): Promise<PickupPointDetails[]> => getPickupPointsList(requestParams),
    {
      enabled: !!selectedDeliveryType,
      onSuccess: (data: PickupPointDetails[]): void => {
        setMapPoints(data
          .map((pickupPoint: PickupPointDetails): MapPointDelivery => ({
            name: pickupPoint.name,
            id: pickupPoint.id,
            address: [pickupPoint.location?.address ?? '', `${`${pickupPoint.location?.postalCode} ${pickupPoint.location?.city}`}`],
            lat: pickupPoint.location?.latitude ?? 0,
            lng: pickupPoint.location?.longitude ?? 0,
            externalId: pickupPoint.externalId,
            openingHours: pickupPoint.openingHours.length 
              ? pickupPoint.openingHours.map((openingHours: PickupPointOpeningHours): OpeningDetails => ({
                dayNo: openingHours.dayNo as OpeningDayNo,
                open: openingHours.open,
                close: openingHours.close,
              })) 
              : undefined,
          })),
        );
        setPickupPoints(data);
      },
      onError: (error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }),
    },
  );

  const activeMarkerUnselect: [number, number] | null = useMemo(
    (): [number, number] | null => {
      const shiftPositionValue: number = 0.00000001;
      if (activePoint) {
        return [activePoint.lat + shiftPositionValue, activePoint.lng + shiftPositionValue];
      } else {
        return null;
      }
    },
    [activePoint],
  );

  const onCloseStoreInfo: () => void = (): void => {
    setActivePointId(null);
    setPointDetails(null);
    setShouldFlyToPoint(false);
    setPointPosition(activeMarkerUnselect);
  };

  const onLocationButtonClick: () => void = (): void => {
    if (currentPosition) {
      setShouldFlyToPoint(true);
      setActivePointId(null);
      setPointDetails(null);
      setPointPosition(currentPosition);
    }
  };

  const onPositionChange: (position: [number, number] | null) => void = (position: [number, number] | null): void => {
    if (position !== currentPosition) {
      setActivePointId(null);
      setPointDetails(null);
    }
    setShouldFlyToPoint(true);
    setCurrentPosition(position);
    setPointPosition(position);
  };

  const onSelectDeliveryPointId: (selectedPointId: number) => void = (selectedPointId: number): void => {
    setActivePointId(selectedPointId);
    setPointDetails(pickupPoints.find((point: PickupPointDetails): boolean => point.id === selectedPointId) ?? null);
    setShouldFlyToPoint(true);
  };

  const onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(e.target.value);
    setPointPosition(null);
    setActivePointId(null);
  };

  useEffect(
    (): void => {
      if (activePoint) {
        setPointPosition([activePoint.lat, activePoint.lng]);
        listRef.current?.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    [activePoint],
  );

  return {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPointId,
    onPositionChange,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition,
    onCloseStoreInfo,
    pointDetails,
    setPointDetails,
  };
};
