import { 
  ButtonTheme, 
  ChoiceBoxBannerFilledData, 
  IconName, 
  OptionSwitchOption, 
  UseNotifications, 
  useNotifications, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { getPickupPointDetails, simulateSubscription } from '@chic/api';
import { QueryKey, RoutingPath, SessionStorage, SubscriptionDeliveryType } from '@chic/enums';
import { 
  SubscriptionSimulation, 
  SubscriptionProductAmount, 
  SubscriptionDeliveryCost, 
  SubscriptionSimulationRequest,
} from '@chic/interfaces';
import { 
  SubscriptionProduct, 
  FrontendApiError, 
  PickupPointDetails, 
  UseSubscriptionsUtils, 
  UseStatics,
  UseSubscriptionDetailsContext,
  SubscriptionPlanProductData, 
} from '@chic/models';
import { emptyRequest, isPickupPointType, isSubscriptionDeliveryType } from '@chic/utils';
import { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { TransProps, useTranslation } from 'react-i18next';
import { UpdateSubscriptionDeliveryBasicData, UseSubscriptionEditDelivery } from '../subscriptionEditDelivery.types';
import { useStatics, useSubscriptionDetailsContext, useSubscriptionsUtils } from '@chic/hooks';
import { UseSubscriptionDetailsActions } from '../../types';
import { useSubscriptionDetailsActions } from '../../hooks';
import { pickupPointsDeliveryTypeCodes } from '@chic/constans';

export const useSubscriptionEditDelivery: () => UseSubscriptionEditDelivery = (): UseSubscriptionEditDelivery => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const { getDeliveryNameByType, getDeliveryLogoByType }: UseStatics = useStatics();
  const { transformPickupPointLocationToAddress }: UseSubscriptionsUtils = useSubscriptionsUtils();
  const { subscriptionDetails, userEditProductsData }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const { updateDeliveryDestination }: UseSubscriptionDetailsActions = useSubscriptionDetailsActions();
  const [simulationDetails, setSimulationDetails]: UseState<SubscriptionSimulation | null> = useState<SubscriptionSimulation | null>(null);
  const [pickupPointId, setPickupPointId]: UseState<string | null> = useState<string | null>(null);
  const [pickupPointData, setPickupPointData]: UseState<PickupPointDetails | null> = useState<PickupPointDetails | null>(null);
  const [selectedDelivery, setSelectedDelivery]: UseState<SubscriptionDeliveryType | null> 
    = useState<SubscriptionDeliveryType | null>(null);
  const currency: string = 'zł';

  useQuery(
    [QueryKey.PickupPointDetails, selectedDelivery, pickupPointId],
    (): Promise<PickupPointDetails | void> => !!pickupPointId && isPickupPointType(selectedDelivery)
      ? getPickupPointDetails(parseInt(pickupPointId, 10), selectedDelivery)
      : emptyRequest(),
    {
      enabled: !!pickupPointId && isPickupPointType(selectedDelivery),
      onSuccess: (point: PickupPointDetails): void => setPickupPointData(point),
      onError: (error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }),
    },
  );

  useEffect(
    (): void => {
      if (!subscriptionDetails) {
        return;
      }

      const selectedDeliveryTypeFromStorage: string | null = sessionStorage.getItem(SessionStorage.SelectedDeliveryType);

      if (selectedDeliveryTypeFromStorage) {
        setSelectedDelivery(selectedDeliveryTypeFromStorage as SubscriptionDeliveryType);
      } else {
        setSelectedDelivery(subscriptionDetails.contract.delivery.type);
      }
      if (sessionStorage.getItem(SessionStorage.PickupPointId)) {
        setPickupPointId(sessionStorage.getItem(SessionStorage.PickupPointId));
      } else if (subscriptionDetails.contract.delivery.pickupPoint?.id) {
        setPickupPointId(String(subscriptionDetails.contract.delivery.pickupPoint.id));
      }
      
      const simulationData: SubscriptionSimulationRequest = !!userEditProductsData?.productsDetails && !!userEditProductsData.products ? {
        products: userEditProductsData.productsDetails.map((product: SubscriptionPlanProductData): SubscriptionProductAmount => ({
          id: product.id,
          amount: userEditProductsData.products[product.id],
        })),
      } : {
        products: subscriptionDetails.contract.products.map((product: SubscriptionProduct): SubscriptionProductAmount => ({
          id: product.productId,
          amount: product.amount,
        })), 
        deliveryType: subscriptionDetails.contract.delivery.type, 
      };

      simulateSubscription(subscriptionDetails.id, simulationData)
        .then(setSimulationDetails)
        .catch((): void => undefined);
    },
    [subscriptionDetails],
  );
  
  const goBack: () => void = (): void => {
    if (!subscriptionDetails) {
      return;
    }

    redirect(RoutingPath.SubscriptionDetailsBasicData, { subscriptionId: subscriptionDetails.id });
  };
  
  const transformSubscriptionDeliveryCostToOptionSwitchOption: (delivery: SubscriptionDeliveryCost) => OptionSwitchOption = (
    delivery: SubscriptionDeliveryCost,
  ): OptionSwitchOption => {

    return {
      name: delivery.code,
      label: delivery.name,
      logo: getDeliveryLogoByType(delivery.code),
      description: !delivery.cost ? t('chic.management.global.free') : `${`(${delivery.cost} ${currency})`}`,
    };
  };

  const mappedDeliveriesToOptionSwitch: OptionSwitchOption[] = useMemo(
    (): OptionSwitchOption[] => simulationDetails?.deliveriesCosts.map(transformSubscriptionDeliveryCostToOptionSwitchOption) ?? [],
    [simulationDetails?.deliveriesCosts],
  );

  const onChangeSelectedDelivery: (name: string) => void = (name: string) => {
    if (isSubscriptionDeliveryType(name)) {
      setSelectedDelivery(name);
    }
  };

  const initialFormValues: UpdateSubscriptionDeliveryBasicData = useMemo(
    (): UpdateSubscriptionDeliveryBasicData => {
      return {
        name: subscriptionDetails?.contract.delivery.name ?? '',
        address: subscriptionDetails?.contract.delivery.address ?? '',
        postalCode: subscriptionDetails?.contract.delivery.postalCode ?? '',
        city: subscriptionDetails?.contract.delivery.city ?? '',
        email: subscriptionDetails?.customer.email ?? '',
        phone: subscriptionDetails?.customer.phone ?? '',
      };
    },
    [subscriptionDetails],
  );

  const choiceBoxBannerFilledData: ChoiceBoxBannerFilledData | null = useMemo(
    (): ChoiceBoxBannerFilledData | null => {
      if (!subscriptionDetails?.id 
        || !selectedDelivery 
        || !pickupPointsDeliveryTypeCodes.includes(selectedDelivery) 
        || !mappedDeliveriesToOptionSwitch.find((option: OptionSwitchOption): boolean => option.name === selectedDelivery)
      ) {
        return null;
      }

      if (!!pickupPointData && isPickupPointType(selectedDelivery)) {
        return {
          title: `${getDeliveryNameByType(selectedDelivery)} ${pickupPointData.externalId}`,
          titleDetails: pickupPointData.name,
          description: pickupPointData.location ? transformPickupPointLocationToAddress(pickupPointData.location) : undefined,
          icon: IconName.ParcelLocker,
          buttonSettings: { 
            label: t('chic.management.global.change'), 
            buttonTheme: ButtonTheme.ICPrimary,
            action: (): void => {
              redirect(RoutingPath.SubscriptionEditDeliveryPoint, { subscriptionId: subscriptionDetails.id });
              sessionStorage.setItem(SessionStorage.SelectedDeliveryType, selectedDelivery);
            }, 
          },
        };
      } else {  
        return {
          title: t('chic.management.useSubscriptionEditDelivery.noPickupPoint'),
          icon: IconName.ParcelLocker,
          buttonSettings: { 
            label: t('chic.management.global.choose'), 
            buttonTheme: ButtonTheme.ICText,
            action: (): void => {
              redirect(RoutingPath.SubscriptionEditDeliveryPoint, { subscriptionId: subscriptionDetails.id });
              sessionStorage.setItem(SessionStorage.SelectedDeliveryType, selectedDelivery ?? '');
            }, 
          },
        };
      }
    },
    [pickupPointData, selectedDelivery, subscriptionDetails?.id, mappedDeliveriesToOptionSwitch],
  );

  const onFormSubmit: (data: UpdateSubscriptionDeliveryBasicData) => void = (data: UpdateSubscriptionDeliveryBasicData) => {
    if (!selectedDelivery || !subscriptionDetails) {
      return;
    }

    const productsToEdit: Record<number, number> | undefined = userEditProductsData?.products;

    updateDeliveryDestination(subscriptionDetails.id, {
      deliveryDestination: {
        address: data.address,
        deliveryType: selectedDelivery,
        pickupPointId: isPickupPointType(selectedDelivery) && pickupPointData?.id ? pickupPointData.id : null,
        name: data.name,
        city: data.city,
        postalCode: data.postalCode,
      },
      products: productsToEdit ? Object.entries(productsToEdit).map((product: [string, number]): SubscriptionProductAmount => ({
        id: parseInt(product[0], 10),
        amount: product[1],
      })) : undefined,
    }, !!Object.keys(productsToEdit ?? {}).length);
  };
  
  return {
    mappedDeliveriesToOptionSwitch, 
    goBack,
    subscriptionDetails,
    selectedDelivery,
    onChangeSelectedDelivery,
    initialFormValues,
    choiceBoxBannerFilledData,
    onFormSubmit,
    pickupPointData,
  };
};
