import { SortOrder, SubscriptionSortKey, SubscriptionStatus } from '@chic/enums';
import { SubscriptionsListState, SubscriptionStatusDetails } from './subscriptionsList.types';
import { BadgeColorTheme } from '@chic-loyalty/ui';

export const subscriptionsListInitialState: SubscriptionsListState = {
  sortKey: SubscriptionSortKey.Default,
  sortOrder: SortOrder.Descending,
  filterValue: '',
};

export const subscriptionStatus: Record<SubscriptionStatus, SubscriptionStatusDetails> = {
  [SubscriptionStatus.Active]: {
    theme: BadgeColorTheme.Green,
    label: 'chic.management.subscriptionsList.subscriptionStatus.active',
  },
  [SubscriptionStatus.Inactive]: {
    theme: BadgeColorTheme.Gray,
    label: 'chic.management.subscriptionsList.subscriptionStatus.inactive',
  },
}; 
