export enum QueryKey {
  SetupDetails = 'setupDetails',
  ScreenDetails = 'screenDetails',
  AdsList = 'adsList',
  AdsStatics = 'adsStatics',
  DiscountCodes = 'discountCodes',
  UserRoles = 'userRoles',
  UsersVerificationList = 'usersVerificationList',
  CodeDetails = 'codeDetails',
  CodeTransactions = 'codeTransactions',
  SubscriptionDetails = 'subscriptionDetails',
  SubscriptionsList = 'subscriptionsList',
  SubscriptionsStatics = 'subscriptionsStatics',
  SubscriptionPlans = 'subscriptionPlans',
  RefundsList = 'refundsList',
  PickupPointsList = 'pickupPointsList',
  PickupPointDetails = 'pickupPointsDetails',
  Config = 'config',
}
