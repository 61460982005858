import { CalcSize, Breakpoint, Color, StyledComponent, TabSwitch } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { ContainerProps } from './baseViewForSubscriptionDetails.types';
import { FileFromViews } from '@chic/enums';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${({ $background }: ContainerProps): FileFromViews => $background});
  background-size: cover;
  
  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICGray500};
    background-position: right;
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledTabSwitch: StyledComponent<typeof TabSwitch> = styled(TabSwitch)`
  max-width: 812px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ChildrenContainer: StyledComponent<'div'> = styled.div`
  padding: 16px 0 0;
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  height: 120px;
  padding: 24px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  justify-content: center;
  gap: 36px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    min-height: 104px;
    padding: 28px;
  }
`;
