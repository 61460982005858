import { SubscriptionDetailsContext } from '@chic/contexts';
import { SubscriptionDetailsAction } from '@chic/enums';
import { SubscriptionDetails, SubscriptionDetailsContextType, UserEditProductsData, UseSubscriptionDetailsContext } from '@chic/models';
import { useContext } from 'react';

export const useSubscriptionDetailsContext: () => UseSubscriptionDetailsContext = (): UseSubscriptionDetailsContext => {
  const [contextData, dispatch]: SubscriptionDetailsContextType = useContext(SubscriptionDetailsContext);

  const updateSubscriptionDetailsData: (data: SubscriptionDetails | null) => void = (
    data: SubscriptionDetails | null,
  ): void => {
    dispatch({ type: SubscriptionDetailsAction.UpdateSubscriptionDetailsData, payload: data });
  };

  const updateUserEditProductsData: (data: UserEditProductsData) => void = (
    data: UserEditProductsData,
  ): void => {
    dispatch({ type: SubscriptionDetailsAction.UpdateUserEditProductsData, payload: data });
  };

  const clearUserEditProductsData: () => void = (): void => {
    dispatch({ type: SubscriptionDetailsAction.ClearUserEditProductsData });
  };

  return { 
    subscriptionDetails: contextData.subscriptionDetails,
    subscriptionPlans: contextData.subscriptionPlans ?? [],
    userEditProductsData: contextData.userEditProductsData,
    userSubscriptionPlan: contextData.userSubscriptionPlan,
    userSubscriptionPlanProductsMap: contextData.userSubscriptionPlanProductsMap,
    updateSubscriptionDetailsData,
    updateUserEditProductsData,
    clearUserEditProductsData,
  };
};
