import { SubscriptionPickupPointType } from '@chic/enums';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { 
  ApiReject, 
  ApiResolve,
  ConfigElement,
  SubscriptionPlan,
  PickupPointDetails,
  PickupPointsListRequest,
  SubscriptionsStatics,
} from '@chic/models';
import { api } from '../../api.service';

export const getSubscriptionsStatics: () => Promise<SubscriptionsStatics> = (): Promise<SubscriptionsStatics> => new Promise(
  (resolve: ApiResolve<SubscriptionsStatics>, reject: ApiReject): Promise<void> => (
    api.get('/management/static/subscription')
      .then((response: AxiosResponse<SubscriptionsStatics>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getPickupPointsList: (params: PickupPointsListRequest) => Promise<PickupPointDetails[]> = (
  params: PickupPointsListRequest,
): Promise<PickupPointDetails[]> => new Promise(
  (resolve: ApiResolve<PickupPointDetails[]>, reject: ApiReject): Promise<void> => (
    api.get(`/management/static/pickup-points?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<PickupPointDetails[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getPickupPointDetails: (id: number, type: SubscriptionPickupPointType) => Promise<PickupPointDetails> = (
  id: number, type: SubscriptionPickupPointType,
): Promise<PickupPointDetails> => new Promise(
  (resolve: ApiResolve<PickupPointDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/static/pickup-points/${type}/${id}`)
      .then((response: AxiosResponse<PickupPointDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getStaticConfig: () => Promise<ConfigElement[]> = (): Promise<ConfigElement[]> => new Promise(
  (resolve: ApiResolve<ConfigElement[]>, reject: ApiReject): Promise<void> => (
    api.get('/management/static/config')
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSubscriptionPlans: () => Promise<SubscriptionPlan[]> = (): Promise<SubscriptionPlan[]> => new Promise(
  (resolve: ApiResolve<SubscriptionPlan[]>, reject: ApiReject): Promise<void> => (
    api.get('/management/static/subscription-plan')
      .then((response: AxiosResponse<SubscriptionPlan[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);
