import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { WelcomeBoxProps } from './welcomeBox.types';
import { Container, Message } from './welcomeBox.styled';

export const WelcomeBox: React.FC<WelcomeBoxProps> = (props: WelcomeBoxProps): JSX.Element => {
  const { name }: WelcomeBoxProps = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <Container data-testid='container'>
      <Message text={t('chic.crmApp.dashboardView.welcomeBox.message', { name })} />
    </Container>
  );
};
