import { 
  BundleDiscountData, 
  FilterElementSettings, 
  IconName, 
  PriceRange, 
  PromotionBannerSettings, 
  useParsers, 
  UseParsers, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { RoutingPath, SubscriptionPlanPropertyType } from '@chic/enums';
import { TransProps, useTranslation } from 'react-i18next';
import { getPlanPropertyValueByType } from '@chic/utils';
import { useEffect, useMemo, useState } from 'react';
import { useSubscriptionDetailsData } from '../hooks';
import { UseSubscriptionDetailsData } from '../types';
import { 
  SubscriptionPlan,
  SubscriptionPlanProductData, 
  SubscriptionProduct, 
  SubscriptionProductsGroup,
  UseSubscriptionDetailsContext, 
} from '@chic/models';
import { simulateSubscription } from '@chic/api';
import { UseSubscriptionEditProducts } from './subscriptionEditProducts.types';
import { SubscriptionProductAmount, SubscriptionSimulation, SubscriptionSimulationRequest } from '@chic/interfaces';
import { useSubscriptionDetailsContext } from '@chic/hooks';

export const useSubscriptionEditProducts: () => UseSubscriptionEditProducts = (): UseSubscriptionEditProducts => {
  const { t }: TransProps<never> = useTranslation();
  const { userEditProductsData, updateUserEditProductsData }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const { 
    subscriptionDetails, 
    subscriptionPlans, 
    userSubscriptionPlan,
    isProductAvailable,
    userSubscriptionPlanProductsMap,
  }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const { redirect }: UseRedirect = useRedirect();
  const { parsePrice }: UseParsers = useParsers();
  const [activeFilter, setActiveFilter]: UseState<string> = useState<string>('');
  const [nextActiveFilter, setNextActiveFilter]: UseState<string> = useState<string>('');
  const [userProducts, setUserProducts]: UseState<Record<number, number>> = useState<Record<number, number>>({});
  const [simulationData, setSimulationData]: UseState<SubscriptionSimulation | null> = useState<SubscriptionSimulation | null>(null);
  const [isPriceOutsidePlan, setIsPriceOutsidePlan]: UseState<boolean> = useState<boolean>(false);
  const [allUserProductsValue, setAllUserProductsValue]: UseState<number> = useState<number>(0);
  const currency: string = 'zł';

  useEffect(
    (): void => {
      if (!subscriptionDetails) {
        return;
      }
      
      let userProductsAmount: Record<number, number> = {};
      if (userEditProductsData?.products) {
        userProductsAmount = userEditProductsData.products;
      } else {
        subscriptionDetails?.contract.products.forEach((product: SubscriptionProduct): void => {
          userProductsAmount[product.productId] = product.amount;
        });
      }

      if (!Object.keys(userProducts).length) {
        setUserProducts(userProductsAmount);
      }

      if (!simulationData && Object.keys(userProductsAmount).length) {
        const simulationRequestData: SubscriptionSimulationRequest = {
          products: Object.keys(userProductsAmount)
            .map((key: string): SubscriptionProductAmount => ({
              id: parseInt(key, 10),
              amount: userProductsAmount[parseInt(key, 10)],
            })),
        };
    
        simulateSubscription(subscriptionDetails.id, simulationRequestData)
          .then(setSimulationData)
          .catch((): void => undefined);
      }
    },
    [subscriptionDetails],
  );

  const planPriceFrom: number = useMemo(
    (): number => {
      if (!userSubscriptionPlan) {
        return 0;
      }

      return getPlanPropertyValueByType<number>(
        userSubscriptionPlan.properties, SubscriptionPlanPropertyType.PriceFrom,
      ) ?? 0;
    },
    [userSubscriptionPlan],
  );

  const planPriceTo: number = useMemo(
    (): number => {
      if (!userSubscriptionPlan) {
        return 0;
      }

      return getPlanPropertyValueByType<number>(
        userSubscriptionPlan.properties, SubscriptionPlanPropertyType.PriceTo,
      ) ?? 0;
    },
    [userSubscriptionPlan],
  );

  const lastSubscriptionPlanPriceTo: number = useMemo(
    (): number => {
      const lastPlan: SubscriptionPlan = subscriptionPlans[subscriptionPlans.length - 1];
      if (!lastPlan) {
        return 0;
      }

      return getPlanPropertyValueByType<number>(
        lastPlan.properties, SubscriptionPlanPropertyType.PriceTo,
      ) ?? 0;
    },
    [subscriptionPlans],
  );

  const priceRanges: PriceRange[] = useMemo(
    (): PriceRange[] => {
      if (planPriceFrom < 0 || !planPriceTo) {
        return [];
      }

      return [
        {
          label: `${planPriceFrom}-${planPriceTo} ${currency}`,
          minValue: planPriceFrom,
          maxValue: planPriceTo,
        },
      ];
    },
    [planPriceFrom, planPriceTo],
  );

  const discounts: BundleDiscountData[] = useMemo(
    (): BundleDiscountData[] => {
      if (!userSubscriptionPlan) {
        return [];
      }

      const discount: number = getPlanPropertyValueByType<number>(
        userSubscriptionPlan.properties, SubscriptionPlanPropertyType.Discount,
      ) ?? 0;
      
      return [
        {
          label: t('chic.management.useSubscriptionEditProducts.discounts.freeDelivery'),
          icon: IconName.CheckInCircle,
          isActive: simulationData?.deliveryPrice.discounted === 0 && !!Object.keys(userProducts).length && !isPriceOutsidePlan,
        },
        {
          label: t('chic.management.useSubscriptionEditProducts.discounts.discount', { discount }),
          icon: IconName.OffersBig,
          isActive: discount > 0 && !!Object.keys(userProducts).length && !isPriceOutsidePlan,
        },
      ];
    },
    [simulationData, userSubscriptionPlan, userProducts, isPriceOutsidePlan],
  );

  const filters: FilterElementSettings[] = useMemo(
    (): FilterElementSettings[] => {
      if (!subscriptionPlans[0]) {
        return [];
      }

      return subscriptionPlans[0].productsGroups.map((group: SubscriptionProductsGroup, index: number): FilterElementSettings => {
        if (index === 0 && !activeFilter) {
          setActiveFilter(group.assetsKey);
        }

        return {
          name: group.assetsKey,
          label: group.name,
          image: group.photo ?? '',
        };
      });
    },
    [subscriptionPlans],
  );

  const promotionBannerDetails: Record<string, PromotionBannerSettings> = useMemo(
    (): Record<string, PromotionBannerSettings> => {
      if (!filters.length) {
        return {};
      }

      const activeFilterIndex: number = filters
        .findIndex((filter: FilterElementSettings): boolean => filter.name === activeFilter);
      const nextFilterIndex: number = activeFilterIndex + 1 >= filters.length ? 0 : activeFilterIndex + 1;
      setNextActiveFilter(filters[nextFilterIndex].name);

      const promotionBannerItems: Record<string, PromotionBannerSettings> = {};
      filters.forEach((filter: FilterElementSettings): void => {
        promotionBannerItems[filter.name] = {
          title: t(`chic.management.subscriptionEditProductsView.promotionBanner.${
            filter.name === 'zg' ? filter.name : 'common'
          }.title`),
          description: t(`chic.management.subscriptionEditProductsView.promotionBanner.${filter.name}.description`),
          background: `https://s.inspirationclub.pl/assets/products/groups/cross-selling/web/desktop/${filter.name}-bg.png`,
          mobileBackground: `https://s.inspirationclub.pl/assets/products/groups/cross-selling/web/mobile/${filter.name}-bg.png`,
          link: { action: (): void => setActiveFilter(filters[nextFilterIndex].name) },
        };
      });

      return promotionBannerItems;
    },
    [filters, activeFilter],
  );

  const products: SubscriptionPlanProductData[] = useMemo(
    (): SubscriptionPlanProductData[] => {
      if (!subscriptionPlans[0]?.productsGroups.length) {
        return [];
      }

      const activeProductsGroup: SubscriptionProductsGroup | undefined = subscriptionPlans[0].productsGroups
        .find((group: SubscriptionProductsGroup): boolean => group.assetsKey === activeFilter);

      return activeProductsGroup?.products ?? [];
    },
    [subscriptionPlans, activeFilter],
  );

  const updateUserProducts: (productId: number, amount: number) => void = (productId: number, amount: number): void => {
    if (!subscriptionDetails || !userSubscriptionPlan || !userSubscriptionPlanProductsMap) {
      return;
    }

    const updateProductFromMap: SubscriptionPlanProductData | undefined = userSubscriptionPlanProductsMap.get(productId);

    const userProductsCopy: Record<number, number> = { ...userProducts };
    if (amount > 0) {
      userProductsCopy[productId] = amount;
    } else {
      delete userProductsCopy[productId];
    }
    setUserProducts(userProductsCopy);

    let value: number = 0;
    Object.keys(userProductsCopy).forEach((key: string): void => {
      if (updateProductFromMap) {
        const foundProduct: SubscriptionPlanProductData | undefined = userSubscriptionPlanProductsMap.get(parseInt(key, 10));
        if (foundProduct) {
          value += (foundProduct.price * userProductsCopy[parseInt(key, 10)]);
        }
      }
    });
    
    const isPriceInsidePlan: boolean = planPriceFrom <= value && value <= planPriceTo;
    setIsPriceOutsidePlan(!isPriceInsidePlan);

    setAllUserProductsValue(value);

    if (isPriceInsidePlan) {

      const simulationRequestData: SubscriptionSimulationRequest = {
        products: Object.keys(userProductsCopy)
          .map((key: string): SubscriptionProductAmount => ({
            id: parseInt(key, 10),
            amount: userProductsCopy[parseInt(key, 10)],
          })),
      };

      simulateSubscription(subscriptionDetails.id, simulationRequestData)
        .then(setSimulationData)
        .catch((): void => undefined);
    }
  };

  const chosenProductsDetails: SubscriptionPlanProductData[] = useMemo(
    (): SubscriptionPlanProductData[] => {
      if (!products || !userSubscriptionPlan || !userSubscriptionPlanProductsMap) {
        return [];
      }

      let chosenProducts: SubscriptionPlanProductData[] = [];
      Object.keys(userProducts).forEach((productId: string): void => {
        const foundProduct: SubscriptionPlanProductData | undefined = userSubscriptionPlanProductsMap.get(parseInt(productId, 10));

        if (foundProduct) {
          chosenProducts = [ ...chosenProducts, foundProduct ];
        }
      });

      return chosenProducts;
    },
    [userProducts, products, userSubscriptionPlan, userSubscriptionPlanProductsMap],
  );

  const goBack: () => void = (): void => {
    if (!subscriptionDetails?.id) {
      return;
    }

    redirect(RoutingPath.SubscriptionDetailsBasicData, { subscriptionId: subscriptionDetails.id });
  };

  const goToSummary: () => void = (): void => {
    if (!Object.keys(userProducts).length || isPriceOutsidePlan || !subscriptionDetails?.id || !simulationData) {
      return;
    }
    
    updateUserEditProductsData({
      products: userProducts,
      productsDetails: chosenProductsDetails,
      simulationData,
    });
    redirect(RoutingPath.SubscriptionEditProductsSummary, { subscriptionId: subscriptionDetails.id });
  };

  const userProductsAmount: number = useMemo(
    (): number => {
      let amount: number = 0;
      Object.values(userProducts).forEach((productAmount: number): void => {
        amount += productAmount;
      });

      return amount;
    },
    [userProducts],
  );

  const priceOutsidePlanLabels: string[] = useMemo(
    (): string[] => {
      if (!userSubscriptionPlan || !isPriceOutsidePlan) {
        return [];
      }

      if (planPriceFrom > allUserProductsValue) {
        const price: string = `${parsePrice(planPriceFrom - allUserProductsValue)} ${currency}`;
        return [
          t('chic.management.useSubscriptionEditProducts.priceOutsidePlanLabels.below.title'),
          t('chic.management.useSubscriptionEditProducts.priceOutsidePlanLabels.below.description', { price }),
        ];
      } else if (allUserProductsValue > planPriceTo) {
        const price: string = `${parsePrice(allUserProductsValue - planPriceTo)} ${currency}`;
        return [
          t('chic.management.useSubscriptionEditProducts.priceOutsidePlanLabels.above.title'),
          t('chic.management.useSubscriptionEditProducts.priceOutsidePlanLabels.above.description', { price }),
        ];
      } else {
        return [];
      }
    },
    [isPriceOutsidePlan, allUserProductsValue, userSubscriptionPlan, planPriceFrom, planPriceTo],
  );
  
  return { 
    priceRanges, 
    filters, 
    activeFilter, 
    setActiveFilter, 
    products, 
    userProducts, 
    updateUserProducts,
    simulationData,
    goBack,
    discounts,
    allUserProductsValue,
    isPriceOutsidePlan,
    chosenProductsDetails,
    goToSummary,
    promotionBannerDetails,
    userProductsAmount,
    priceOutsidePlanLabels,
    planPriceFrom,
    isProductAvailable,
    lastSubscriptionPlanPriceTo,
    nextActiveFilter,
  };
};
