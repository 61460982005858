import { 
  Breakpoint,
  Button,
  ButtonTheme,
  DetailsEditBox, 
  DetailsEditBoxRow, 
  InfoBox, 
  InfoBoxTheme, 
  OrderSummaryBox, 
  PageTitle, 
  PageTitleSize,
  UseParsers,
  useParsers, 
} from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ButtonsBox,
  Container, 
  DetailsBox, 
  DetailsEditBoxes, 
  InnerContainer, 
  OrderSummaryContainer, 
  PageTitleWrapper,
  ProductsBox,
  StyledInfoBox,
  StyledTransactionProductBox, 
} from './subscriptionOrder.styled';
import { UseSubscriptionOrder } from './subscriptionOrder.types';
import { useSubscriptionOrder } from './subscriptionOrder.hooks';
import { Params, useParams } from 'react-router-dom';
import { SubscriptionProduct, UseStatics } from '@chic/models';
import { useStatics, useTitle } from '@chic/hooks';
import { SubscriptionPaymentType, SubscriptionProductAvailability } from '@chic/enums';
import { useMediaQuery } from 'react-responsive';
import { getPaymentTypeLogo } from '@chic/utils';
import { SubscriptionParameterData } from '@chic/types';

export const SubscriptionOrderView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId }: Params = useParams();
  const { 
    goBack, 
    currency, 
    products, 
    deliveryAddress, 
    subscriptionDetails, 
    onCreateOrder,
    deliveryBadge,
    simulationDetails,
    isPaymentTypeCard,
    parameters,
    canCreateOrder,
    hasUnavailableProducts,
  }: UseSubscriptionOrder = useSubscriptionOrder(subscriptionId ?? '');
  const { getPaymentNameByType, getDeliveryNameByType, getParameterLabel, getParameterOptionLabel }: UseStatics = useStatics();
  const { parsePrice }: UseParsers = useParsers();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.subscriptionOrderView.title'));

  if (!subscriptionDetails) { 
    return <></>;
  }

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.subscriptionOrderView.pageTitle')} 
          onClick={goBack}
        />
      </PageTitleWrapper>
      <InnerContainer>
        <PageTitle label={t('chic.management.subscriptionOrderView.products.title')} size={PageTitleSize.Small} />
        <ProductsBox>
          {products?.map((product: SubscriptionProduct): JSX.Element => (
            <StyledTransactionProductBox 
              key={product.productId}
              name={product.productName}
              category={product.categoryName}
              amount={product.amount}
              price={product.unitPrice}
              ean={product.ean}
              image={product.image ?? undefined}
              unavailable={![
                SubscriptionProductAvailability.Available, 
                SubscriptionProductAvailability.PartiallyAvailable,
              ].includes(product.availability)}
            />
          ))}
        </ProductsBox>
        {hasUnavailableProducts && (
          <StyledInfoBox 
            title={t('chic.management.subscriptionOrderView.hasUnavailableProducts.title')}
            description={t('chic.management.subscriptionOrderView.hasUnavailableProducts.description')}
            infoBoxTheme={InfoBoxTheme.Red}
          />
        )}
        <PageTitle label={t('chic.management.subscriptionOrderView.details.title')} size={PageTitleSize.Small} />
        <DetailsBox>
          <DetailsEditBoxes>
            <DetailsEditBox 
              title={t('chic.management.subscriptionOrderView.details.address')} 
              rows={[
                {
                  label: t('chic.management.subscriptionOrderView.details.deliveryType'),
                  textContent: getDeliveryNameByType(subscriptionDetails.contract.delivery.type),
                  badge: deliveryBadge,
                },
                {
                  label: t('chic.management.subscriptionOrderView.details.deliveryAddress'),
                  textContent: deliveryAddress,
                },
              ]} 
            />
            <DetailsEditBox 
              title={t('chic.management.subscriptionOrderView.details.payment')} 
              rows={[
                { 
                  label: getPaymentNameByType(
                    isPaymentTypeCard ? SubscriptionPaymentType.Payu : subscriptionDetails.contract.paymentType,
                  ),
                  logo: getPaymentTypeLogo(
                    isPaymentTypeCard ? SubscriptionPaymentType.Payu : subscriptionDetails.contract.paymentType,
                  ),
                },
                ...(parameters.map((parameter: SubscriptionParameterData): DetailsEditBoxRow => ({
                  label: getParameterLabel(parameter.code),
                  textContent: getParameterOptionLabel(parameter.code, parameter.value),
                }))),
              ]} 
            />
          </DetailsEditBoxes>
          {isPaymentTypeCard && (
            <InfoBox 
              title={t('chic.management.subscriptionOrderView.infoBox.title')} 
              description={t('chic.management.subscriptionOrderView.infoBox.description')}
              infoBoxTheme={InfoBoxTheme.Red} 
            />
          )}
        </DetailsBox>
        <PageTitle label={t('chic.management.subscriptionOrderView.summary.title')} size={PageTitleSize.Small} />
        <OrderSummaryContainer>
          {!!simulationDetails && (
            <OrderSummaryBox 
              items={[
                {
                  label: t('chic.management.subscriptionOrderView.summary.price'),
                  value: `${parsePrice(simulationDetails.productsPrice.regular)} ${currency}`,
                },
                {
                  label: t('chic.management.subscriptionOrderView.summary.discount'),
                  value: `${parsePrice(simulationDetails.productsPrice.discountValue)} ${currency}`,
                  badge: subscriptionDetails.contract.discountPercentage 
                    ? `${subscriptionDetails.contract.discountPercentage}%` 
                    : undefined,
                },
                {
                  label: t('chic.management.subscriptionOrderView.summary.delivery'),
                  value: simulationDetails.deliveryPrice.discounted !== 0 
                    ? `${parsePrice(simulationDetails.deliveryPrice.discounted)} ${currency}`
                    : t('chic.management.global.free'),
                },
              ]} 
              highlightedItem={{
                label: t('chic.management.subscriptionOrderView.summary.fullPrice'),
                value: `${parsePrice(simulationDetails.productsPrice.discounted + simulationDetails.deliveryPrice.discounted)} ${currency}`,
              }}        
            />
          )}
        </OrderSummaryContainer>
      </InnerContainer>
      <ButtonsBox>
        <Button 
          label={t('chic.management.global.cancel')} 
          buttonTheme={ButtonTheme.ICSecondary} 
          onClick={goBack}
          reverted={!isMobile}
          fullWidth={isMobile}
        />
        <Button 
          label={t('chic.management.subscriptionOrderView.createOrder')} 
          buttonTheme={ButtonTheme.ICPrimary} 
          onClick={onCreateOrder} 
          disabled={!canCreateOrder || hasUnavailableProducts}
          fullWidth={isMobile}
        />
      </ButtonsBox>
    </Container>
  );
};
