import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForAnimationsProps } from './baseViewForAnimations.types';
import { 
  ButtonBannersBox, 
  ButtonBannersWrapper, 
  ButtonsWrapper, 
  Container, 
  FileSettingsBoxWrapper, 
  InnerContainer, 
  InputWrapper, 
  ItemPickerTitle, 
  ItemPickerWrapper, 
  MobileHeaderButton, 
  PageTitleWrapper, 
} from './baseViewForAnimations.styled';
import { 
  AddFile, 
  Breakpoint, 
  Button, 
  ButtonBanner, 
  ButtonBannerSettings, 
  ButtonTheme, 
  EditItemsRows, 
  FileSettingsBoxes, 
  Input, 
  ItemPicker, 
  MenuPosition, 
  PageTitle, 
  PageTitleSize, 
} from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';

export const BaseViewForAnimations: React.FC<BaseViewForAnimationsProps> = (props: BaseViewForAnimationsProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    title, 
    goBack, 
    onNameChange,
    nameInputLabel,
    addFileProps,
    fileSettingsBoxesProps,
    headerButton, 
    acceptButtonSettings,
    cancelButtonSettings,
    buttonBanners,
    itemPickerSettings,
    screensRows,
    animationName,
    withBackgroundImage,
  }: BaseViewForAnimationsProps = props;
  const isCustomDesktop: boolean = useMediaQuery({ query: Breakpoint.CustomDesktop });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  return (
    <Container $withBackgroundImage={!!withBackgroundImage}>
      {!!headerButton && !!isMobile && (
        <MobileHeaderButton>
          <MenuPosition {...headerButton}>
            <Button  
              buttonTheme={ButtonTheme.ICTextWhite} 
              label={headerButton.label} 
            />
          </MenuPosition>
        </MobileHeaderButton>
      )}
      <PageTitleWrapper>
        <PageTitle 
          label={title} 
          size={PageTitleSize.Big} 
          onClick={goBack}
        />
        {!!headerButton && !isMobile && (
          <MenuPosition {...headerButton}>
            <Button  
              buttonTheme={ButtonTheme.ICTextWhite} 
              label={headerButton.label} 
            />
          </MenuPosition>
        )}
      </PageTitleWrapper>
      <InnerContainer>
        <InputWrapper>
          <Input 
            label={nameInputLabel} 
            placeholder={t('chic.management.baseViewForAnimations.input.placeholder')}
            onBlur={onNameChange}
            value={animationName}
            required
          />
        </InputWrapper>
        <AddFile {...addFileProps} column={isCustomDesktop && !isTablet} maxDuration={20} />
        <FileSettingsBoxWrapper>
          <FileSettingsBoxes {...fileSettingsBoxesProps} />
        </FileSettingsBoxWrapper>
        {!!buttonBanners?.length && (
          <ButtonBannersWrapper>
            <PageTitle label={t('chic.management.baseViewForAnimations.screens.pageTitle')} />
            <ButtonBannersBox>
              {buttonBanners.map((buttonBanner: ButtonBannerSettings): JSX.Element => (
                <ButtonBanner {...buttonBanner} key={buttonBanner.title} />
              ))}
            </ButtonBannersBox>
            {!!screensRows?.length && (
              <EditItemsRows rows={screensRows} />
            )}
          </ButtonBannersWrapper>
        )}
        {!!itemPickerSettings && (
          <ItemPickerWrapper>
            <ItemPickerTitle>
              <PageTitle label={t('chic.management.baseViewForAnimations.targets.pageTitle')} />
            </ItemPickerTitle>
            <ItemPicker {...itemPickerSettings} />
          </ItemPickerWrapper>
        )}
      </InnerContainer>
      <ButtonsWrapper>
        <MenuPosition {...cancelButtonSettings}>
          <Button 
            buttonTheme={ButtonTheme.ICSecondary} 
            label={cancelButtonSettings.label} 
            disabled={cancelButtonSettings.disabled}
          />
        </MenuPosition>
        <MenuPosition {...acceptButtonSettings}>
          <Button 
            buttonTheme={ButtonTheme.ICPrimary} 
            label={acceptButtonSettings.label} 
            disabled={acceptButtonSettings.disabled}
          />
        </MenuPosition>
      </ButtonsWrapper>
    </Container>
  );
};
