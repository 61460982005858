import { BaseViewForSubscriptionDetails } from '@chic/components';
import React, { useEffect, useState } from 'react';
import { FileFromViews, SubscriptionContractAmendmentStatus, SubscriptionDetailsPageName } from '@chic/enums';
import { Params, useParams } from 'react-router-dom';
import { TransProps, useTranslation } from 'react-i18next';
import { UseSubscriptionDetailsData } from '../types';
import { useSubscriptionDetailsData } from '../hooks';
import { InnerContainer } from './subscriptionDetailsAmendmentsHistory.styled';
import { 
  Badge, 
  BadgeColorTheme, 
  BadgeSize, 
  Breakpoint, 
  Color, 
  DetailsRowTheme, 
  DetailsTable, 
  DetailsTableCellType, 
  DetailsTableRow, 
  IconName, 
  ResultInfoBox, 
  UseState, 
  VisibilitySettings, 
} from '@chic-loyalty/ui';
import { AmendmentsTableColumnName } from '../enums';
import { SubscriptionContractAmendment } from '@chic/models';
import { useMediaQuery } from 'react-responsive';

export const SubscriptionDetailsAmendmentsHistoryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId }: Params = useParams();
  const { subscriptionDetails, actionsPermissions }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const [amendmentsTable, setAmendmentsTable]: UseState<DetailsTableRow[]> = useState<DetailsTableRow[]>([]);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  const amendmentStatusTheme: Record<SubscriptionContractAmendmentStatus, BadgeColorTheme> = {
    [SubscriptionContractAmendmentStatus.Accepted]: BadgeColorTheme.Green,
    [SubscriptionContractAmendmentStatus.Pending]: BadgeColorTheme.Blue,
    [SubscriptionContractAmendmentStatus.Rejected]: BadgeColorTheme.Red,
  };

  useEffect(
    (): void => {
      if (subscriptionDetails?.amendments.length) {
        setAmendmentsTable(
          subscriptionDetails.amendments.map((amendment: SubscriptionContractAmendment): DetailsTableRow => ({
            theme: DetailsRowTheme.Normal,
            cells: [
              {
                name: AmendmentsTableColumnName.ContractCode,
                value: amendment.contractCode,
                visibilitySettings: VisibilitySettings.Always,
                iconDetails: isMobile ? { icon: IconName.FileDoc } : undefined,
                wrap: true,
              },
              {
                name: AmendmentsTableColumnName.Type,
                value: amendment.label,
                visibilitySettings: VisibilitySettings.Always,
                wrap: true,
              },
              {
                name: AmendmentsTableColumnName.Status,
                visibilitySettings: VisibilitySettings.Always,
                cellType: DetailsTableCellType.Children,
                customAttributes: {
                  children: (
                    <Badge 
                      theme={amendmentStatusTheme[amendment.status]} 
                      size={BadgeSize.Small} 
                      label={t(`chic.management.amendmentsTable.status.${amendment.status}`)} 
                    />
                  ),
                },
              },
              {
                name: AmendmentsTableColumnName.CreateDatetime,
                value: amendment.createDatetime,
                visibilitySettings: VisibilitySettings.Always,
                iconDetails: isMobile 
                  ? { 
                    icon: IconName.Calendar,
                    iconColor: Color.ICYellow100,
                  } 
                  : undefined,
                wrap: true,
              },
              {
                name: AmendmentsTableColumnName.UpdateDatetime,
                value: amendment.updateDatetime ?? t('chic.management.global.noData'),
                visibilitySettings: VisibilitySettings.Always,
                iconDetails: isMobile 
                  ? { 
                    icon: IconName.Calendar,
                    iconColor: Color.ICYellow100,
                  } 
                  : undefined,
                wrap: true,
              },
            ],
          })),
        );
      }
    },
    [subscriptionDetails?.amendments, isMobile],
  );

  return (
    <BaseViewForSubscriptionDetails 
      activeTab={SubscriptionDetailsPageName.AmendmentsHistory}  
      subscriptionId={subscriptionId}
      isNewOrderButtonActive={!!actionsPermissions.createOrder}
    >
      <InnerContainer>
        {amendmentsTable.length ? (
          <DetailsTable 
            headers={[
              {
                name: AmendmentsTableColumnName.ContractCode,
                label: t('chic.management.subscriptionDetailsAmendmentsHistory.amendmentsTable.contractCode'),
              }, 
              {
                name: AmendmentsTableColumnName.Type,
                label: t('chic.management.subscriptionDetailsAmendmentsHistory.amendmentsTable.type'),
              }, 
              {
                name: AmendmentsTableColumnName.Status,
                label: t('chic.management.subscriptionDetailsAmendmentsHistory.amendmentsTable.status'),
              }, 
              {
                name: AmendmentsTableColumnName.CreateDatetime,
                label: t('chic.management.subscriptionDetailsAmendmentsHistory.amendmentsTable.createDatetime'),
              }, 
              {
                name: AmendmentsTableColumnName.UpdateDatetime,
                label: t('chic.management.subscriptionDetailsAmendmentsHistory.amendmentsTable.updateDatetime'),
              },
            ]}
            tableData={amendmentsTable}
          />
        ) : (
          <ResultInfoBox 
            text={t('chic.management.subscriptionDetailsAmendmentsHistory.emptyState')}
            backgroundImage={FileFromViews.SubscriptionDetailsAmendmentsHistoryViewEmptyState}
          />
        )}
      </InnerContainer>
    </BaseViewForSubscriptionDetails>
  );
};
