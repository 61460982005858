import { 
  useNotifications, 
  UseNotifications, 
  useParsers, 
  UseParsers, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ConfigKey,
  QueryKey, 
  RoutingPath, 
  SubscriptionParameterType, 
  SubscriptionPaymentType,
  SubscriptionProductAvailability, 
} from '@chic/enums';
import { UseSubscriptionOrder } from './subscriptionOrder.types';
import { useMemo, useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { 
  ConfigElement, 
  FrontendApiError, 
  SubscriptionDetails, 
  SubscriptionProduct, 
  UseSubscriptionDetailsContext, 
  UseSubscriptionsUtils, 
} from '@chic/models';
import { getStaticConfig, makeSubscriptionOrder, simulateSubscription } from '@chic/api';
import { SubscriptionProductAmount, SubscriptionSimulation } from '@chic/interfaces';
import { useSubscriptionDetailsContext, useSubscriptionsUtils } from '@chic/hooks';
import { SubscriptionParameterData } from '@chic/types';
import { useSubscriptionDetailsActionsPermissions, useSubscriptionDetailsData } from '../hooks';
import { UseSubscriptionDetailsActionsPermissions, UseSubscriptionDetailsData } from '../types';

export const useSubscriptionOrder: (id: string) => UseSubscriptionOrder = (id: string): UseSubscriptionOrder => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { parsePrice }: UseParsers = useParsers();
  const { transformLocationToDeliveryAddress }: UseSubscriptionsUtils = useSubscriptionsUtils();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { subscriptionDetails }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const { updateSubscriptionDetailsData }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const [simulationDetails, setSimulationDetails]: UseState<SubscriptionSimulation | null> = useState<SubscriptionSimulation | null>(null);
  const [products, setProducts]: UseState<SubscriptionProduct[]> = useState<SubscriptionProduct[]>([]);
  const currency: string = 'zł';

  const { actionsPermissions }: UseSubscriptionDetailsActionsPermissions = useSubscriptionDetailsActionsPermissions();
  
  const goBack: () => void = (): void => {
    redirect(RoutingPath.SubscriptionDetailsBasicData, { subscriptionId: id });
  };

  const { data: config }: UseQueryResult<ConfigElement[]> = useQuery(
    [QueryKey.Config],
    (): Promise<ConfigElement[]> => getStaticConfig(),
  );

  useEffect(
    (): void => {
      if (!subscriptionDetails) {
        return;
      }

      setProducts(subscriptionDetails.contract.products);

      simulateSubscription(subscriptionDetails.id, { 
        products: subscriptionDetails.contract.products.map((product: SubscriptionProduct): SubscriptionProductAmount => ({
          id: product.productId,
          amount: product.amount,
        })), 
        deliveryType: subscriptionDetails.contract.delivery.type, 
      })
        .then(setSimulationDetails)
        .catch((error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }));
    },
    [subscriptionDetails],
  );

  const deliveryAddress: string = useMemo(
    (): string => {
      if (!subscriptionDetails) {
        return '';
      }

      return transformLocationToDeliveryAddress(subscriptionDetails.contract.delivery);
    },
    [subscriptionDetails],
  );

  const deliveryBadge: string | undefined = useMemo(
    (): string | undefined => {
      if (!simulationDetails || !subscriptionDetails) {
        return;
      }

      return simulationDetails.deliveryPrice.discounted === 0 ? t('chic.management.useSubscriptionOrder.deliveryBadge.free') : undefined;
    },
    [simulationDetails],
  );

  const isPaymentTypeCard: boolean = useMemo(
    (): boolean => {
      if (!subscriptionDetails?.contract.paymentType) {
        return false;
      }

      return SubscriptionPaymentType.Card === subscriptionDetails.contract.paymentType;
    },
    [subscriptionDetails?.contract.paymentType],
  );

  const onCreateOrder: () => void = (): void => {
    if (!id) {
      return;
    }

    makeSubscriptionOrder(parseInt(id, 10))
      .then((orderSubscriptionData: SubscriptionDetails): void => {
        showFullscreenAlert({
          title: t('chic.management.useSubscriptionOrder.makeSubscriptionOrder.success'),
          description: t('chic.management.useSubscriptionOrder.makeSubscriptionOrder.description'),
          nameValueFields: [
            {
              name: t('chic.management.useSubscriptionOrder.makeSubscriptionOrder.price'),
              value: `${parsePrice(orderSubscriptionData.orders[0].price)} ${currency}`,
            },
          ],
          acceptButtonSettings: {
            label: t('chic.management.global.close'),
            action: (): void => {
              hideFullscreenAlert();
              updateSubscriptionDetailsData(orderSubscriptionData);
              redirect(RoutingPath.SubscriptionDetailsCurrentOrder, { subscriptionId: id });
            },
          },
        });
      })
      .catch((error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }));
  };

  const parameters: SubscriptionParameterData[] = useMemo(
    (): SubscriptionParameterData[] => {
      if (!subscriptionDetails) {
        return [];
      }

      const cyclicOrderConfig: boolean = !!config?.find((configElement: ConfigElement): boolean => (
        configElement.name === ConfigKey.SubscriptionParameterCyclicOrderEnabled
      ))?.value;
      const posPaymentConfig: boolean = !!config?.find((configElement: ConfigElement): boolean => (
        configElement.name === ConfigKey.SubscriptionParameterPosPaymentEnabled
      ))?.value;

      return subscriptionDetails.parameters.filter((item: SubscriptionParameterData): boolean => {
        switch (item.code) {
          case SubscriptionParameterType.CyclicOrder:
            return !!item.editable && !!cyclicOrderConfig;
          case SubscriptionParameterType.PosPayment:
            return !!item.editable && !!posPaymentConfig;
          default: 
            return true;
        }
      });
    },
    [subscriptionDetails, config],
  );

  const hasUnavailableProducts: boolean = useMemo(
    (): boolean => {
      return !!subscriptionDetails?.contract.products.find((product: SubscriptionProduct): boolean => ![
        SubscriptionProductAvailability.Available, 
        SubscriptionProductAvailability.PartiallyAvailable,
      ].includes(product.availability));
    },
    [subscriptionDetails?.contract.products],
  );

  return { 
    goBack, 
    currency, 
    products, 
    subscriptionDetails, 
    deliveryAddress, 
    onCreateOrder, 
    deliveryBadge,
    simulationDetails,
    isPaymentTypeCard,
    parameters,
    hasUnavailableProducts,
    canCreateOrder: actionsPermissions.createOrder,
  };
};
