import { 
  Breakpoint, 
  Button, 
  CalcSize, 
  Color, 
  EmptyStateBanner, 
  Gradient, 
  SearchDeliveryListItem, 
  StyledComponent, 
  disableScroll, 
} from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  position: relative;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  padding: 24px 0 0 24px;

  @media ${Breakpoint.Tablet} {
    padding: 24px 0 0;
    min-height: auto;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 24px 0;
  gap: 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0 24px 24px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid ${Color.ICGray500};
  }
`;

export const InputWrapper: StyledComponent<'div'> = styled.div`
  padding: 20px;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  height: 700px;
  width: 651px;
  margin-left: 20px;
  flex-shrink: 0;

  @media ${Breakpoint.Desktop} {
    width: 50%;
  }

  @media ${Breakpoint.Tablet} {
    height: 380px;
    width: 100%;
    margin-left: 0;
  }
`;

export const SearchContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media ${Breakpoint.Tablet} {
    order: 2;
    padding: 20px 0 0;
    height: 375px;
  }
`;

export const ListContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${disableScroll};

  @media ${Breakpoint.Tablet} {
    padding: 0 20px;
  }
`;

export const OuterListContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  height: 100%;
  display: flex;

  @media ${Breakpoint.Tablet} {
    max-height: 100%;
  }
`;

export const StyledSearchDeliveryListItem: StyledComponent<typeof SearchDeliveryListItem> = styled(SearchDeliveryListItem)`
  display: flex;
  padding: 20px 0;

  & + & {
    padding-top: 20px;
    border-top: 1px solid ${Color.ICWhite100};
  }
`;

export const ButtonWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 40px 0;
  
  @media ${Breakpoint.Tablet} {
    justify-content: center;
    width: 100%;
    background: ${Gradient.Dark};
    bottom: 0;
    padding: 32px 20px;
    margin: 20px 0 0;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-left: 12px;

  @media ${Breakpoint.Tablet} {
    width: calc(100% - 52px);
  }
`;

export const StyledEmptyStateBanner: StyledComponent<typeof EmptyStateBanner> = styled(EmptyStateBanner)`
  margin-top: 20px;

  @media ${Breakpoint.Tablet} {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;
