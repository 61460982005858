import { useTitle } from '@chic/hooks';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ButtonsWrapper, 
  Container, 
  EmptyState, 
  EmptyStateDescription, 
  EmptyStateDetails, 
  EmptyStateTitle, 
  EmptyStateWrapper, 
  InnerContainer, 
  ModalBoxInner, 
  PageTitleWrapper, 
  ProductsGrid,
  StyledInfoBox,
  StyledOrderSummaryBox,
  StyledPromotionBanner,
  StyledSubscriptionSummaryBox,
  StyledTransactionProductBox,
  TransactionProducts,
} from './subscriptionEditProducts.styled';
import { 
  Button,
  ButtonTheme,
  Filters, 
  InfoBoxTheme, 
  ModalBox, 
  PageTitle, 
  PageTitleSize, 
  ProductBox, 
  SubscriptionSummaryBox,
  useParsers,
  UseParsers,
  UseState, 
} from '@chic-loyalty/ui';
import { UseSubscriptionEditProducts } from './subscriptionEditProducts.types';
import { useSubscriptionEditProducts } from './subscriptionEditProducts.hooks';
import { SubscriptionPlanProductData } from '@chic/models';
import { UseSubscriptionDetailsActionsPermissions } from '../types';
import { useSubscriptionDetailsActionsPermissions } from '../hooks';
import { FileFromViews } from '@chic/enums';

export const SubscriptionEditProductsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { 
    priceRanges,
    filters,
    activeFilter,
    setActiveFilter,
    products,
    userProducts,
    updateUserProducts,
    simulationData,
    goBack,
    discounts,
    allUserProductsValue,
    isPriceOutsidePlan,
    chosenProductsDetails,
    goToSummary,
    promotionBannerDetails,
    userProductsAmount,
    priceOutsidePlanLabels,
    planPriceFrom,
    isProductAvailable,
    lastSubscriptionPlanPriceTo,
    nextActiveFilter,
  }: UseSubscriptionEditProducts = useSubscriptionEditProducts();
  const { actionsPermissions }: UseSubscriptionDetailsActionsPermissions = useSubscriptionDetailsActionsPermissions();
  useTitle(t('chic.management.subscriptionEditProductsView.title'));
  const [isProductsModalOpen, setIsProductsModalOpen]: UseState<boolean> = useState<boolean>(false);
  const currency: string = 'zł';

  const onUseModal: (value: boolean) => void = (value: boolean): void => {
    setIsProductsModalOpen(value);

    if (!isProductsModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  const isSaveAvailable: boolean = useMemo(
    (): boolean => {
      return !chosenProductsDetails.find((product: SubscriptionPlanProductData): boolean => (
        !isProductAvailable(product.availability, product.id)
      )) 
      && !!Object.keys(userProducts ?? {}).length 
      && !!actionsPermissions.updateProducts
      && !isPriceOutsidePlan;
    },
    [chosenProductsDetails, userProducts, actionsPermissions, isPriceOutsidePlan],
  );

  return (
    <Container>
      <ModalBox 
        title={t('chic.management.subscriptionEditProductsView.modal.pageTitle')} 
        isOpen={isProductsModalOpen}
        buttonSettings={isSaveAvailable ? {
          label: t('chic.management.global.goNext'),
          action: (): void => {
            onUseModal(!isProductsModalOpen);
            goToSummary();
          },
        } : undefined}
        changeModalVisibility={onUseModal}
        countNumber={userProductsAmount}
        backgroundImage={!userProductsAmount ? FileFromViews.SubscriptionEditProductsViewModalBackground : undefined}
      >
        {!userProductsAmount ? (
          <EmptyStateWrapper>
            <EmptyState>
              <EmptyStateDetails>
                <EmptyStateTitle>{t('chic.management.subscriptionEditProductsView.modal.emptyState.title')}</EmptyStateTitle>
                <EmptyStateDescription 
                  text={t(
                    'chic.management.subscriptionEditProductsView.modal.emptyState.description', 
                    { price: `${parsePrice(planPriceFrom)} ${currency}` },
                  )} 
                />
              </EmptyStateDetails>
            </EmptyState>
          </EmptyStateWrapper>
        ) : (
          <ModalBoxInner>
            <StyledSubscriptionSummaryBox 
              priceRanges={priceRanges} 
              value={isPriceOutsidePlan ? allUserProductsValue : simulationData?.productsPrice.regular ?? 0} 
              finalValue={isPriceOutsidePlan ? allUserProductsValue : simulationData?.productsPrice.discounted ?? 0} 
            />
            {!!priceOutsidePlanLabels.length && (
              <StyledInfoBox 
                title={priceOutsidePlanLabels[0]}
                description={priceOutsidePlanLabels[1]}
                infoBoxTheme={InfoBoxTheme.Red}  
                $isInModal
              />
            )}
            <TransactionProducts>
              {chosenProductsDetails.map((product: SubscriptionPlanProductData): JSX.Element => (
                <StyledTransactionProductBox 
                  name={product.name}
                  category={product.category}
                  ean={product.ean}
                  price={product.price}
                  image={product.photos[0]}
                  key={product.id}
                  amount={userProducts[product.id] ?? 0}
                  unavailable={!isProductAvailable(product.availability, product.id)}
                  onChangeProductCount={(value: number): void => updateUserProducts(product.id, value)}
                  maxAmountValue={
                    Math.floor(
                      (lastSubscriptionPlanPriceTo - (userProductsAmount)) / product.price) + (userProducts[product.id] || 0
                    )
                  }
                />
              ))}
            </TransactionProducts>
            <StyledOrderSummaryBox 
              items={[
                {
                  label: t('chic.management.subscriptionEditProductsView.modal.orderSummaryBox.value'),
                  value: `${parsePrice(
                    isPriceOutsidePlan ? allUserProductsValue : simulationData?.productsPrice.regular ?? 0,
                  )} ${currency}`,
                },
                {
                  label: t('chic.management.subscriptionEditProductsView.modal.orderSummaryBox.discount'),
                  value: `${parsePrice(
                    isPriceOutsidePlan ? 0 : simulationData?.productsPrice.discountValue ?? 0,
                  )} ${currency}`,
                  badge: !isPriceOutsidePlan && simulationData?.productsPrice.discountPercent 
                    ? `${simulationData.productsPrice.discountPercent}%` 
                    : undefined,
                },
                {
                  label: t('chic.management.subscriptionEditProductsView.modal.orderSummaryBox.delivery'),
                  value: `${parsePrice(
                    isPriceOutsidePlan ? 0 : simulationData?.deliveryPrice.discounted ?? 0,
                  )} ${currency}`,
                },
              ]} 
              highlightedItem={{
                label: t('chic.management.subscriptionEditProductsView.modal.orderSummaryBox.finalValue'),
                value: `${parsePrice(
                  isPriceOutsidePlan ? allUserProductsValue : (
                    (simulationData?.productsPrice.discounted ?? 0) + (simulationData?.deliveryPrice.discounted ?? 0)
                  ),
                )} ${currency}`,
              }}              
            />
          </ModalBoxInner>
        )}
      </ModalBox>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.subscriptionEditProductsView.pageTitle')} 
          onClick={goBack}
        />
      </PageTitleWrapper>
      {!!priceRanges.length && (
        <SubscriptionSummaryBox 
          priceRanges={priceRanges} 
          discounts={discounts} 
          value={isPriceOutsidePlan ? allUserProductsValue : simulationData?.productsPrice.regular ?? 0} 
          finalValue={isPriceOutsidePlan ? allUserProductsValue : simulationData?.productsPrice.discounted ?? 0} 
        />
      )}
      <InnerContainer>  
        {!!priceOutsidePlanLabels.length && (
          <StyledInfoBox 
            title={priceOutsidePlanLabels[0]}
            description={priceOutsidePlanLabels[1]}
            infoBoxTheme={InfoBoxTheme.Red}  
          />
        )}
        <Filters 
          elements={filters} 
          onChange={setActiveFilter} 
          activeElementName={activeFilter}        
        />
        <ProductsGrid>
          {products.map((product: SubscriptionPlanProductData): JSX.Element => (
            <>
              <ProductBox 
                key={product.id}
                label={product.name} 
                price={`${product.price} ${currency}`} 
                isAvailable={isProductAvailable(product.availability, product.id)}
                onRemoveButtonClick={userProducts[product.id] > 0 ? (): void => updateUserProducts(product.id, 0) : undefined}
                image={product.photos[0]}
                ean={parseInt(product.ean, 10)}
                onChangeProductCount={(value: number): void => updateUserProducts(product.id, value)}
                initialValue={userProducts[product.id] ?? 0}
                maxValue={
                  Math.floor(
                    (lastSubscriptionPlanPriceTo - allUserProductsValue) / product.price) + (userProducts[product.id] || 0
                  )
                }
              />
            </>
          ))}
        </ProductsGrid>
        {!!promotionBannerDetails[nextActiveFilter] && (
          <StyledPromotionBanner {...promotionBannerDetails[nextActiveFilter]} />
        )}
      </InnerContainer>
      <ButtonsWrapper>
        <Button 
          label={t('chic.management.subscriptionEditProductsView.chosenProducts')}
          buttonTheme={ButtonTheme.ICSecondary} 
          onClick={(): void => onUseModal(true)} 
          countNumber={userProductsAmount}
          reverted 
        />
        <Button 
          label={t('chic.management.global.goNext')} 
          buttonTheme={ButtonTheme.ICPrimary} 
          onClick={goToSummary} 
          disabled={!isSaveAvailable}
        />
      </ButtonsWrapper>
    </Container>
  );
};
