import { BaseViewForSubscriptionDetails } from '@chic/components';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { FileFromViews, SubscriptionDetailsPageName, SubscriptionOrderStatus } from '@chic/enums';
import { Params, useParams } from 'react-router-dom';
import { UseSubscriptionDetailsActions, UseSubscriptionDetailsData } from '../types';
import { useSubscriptionDetailsActions, useSubscriptionDetailsData } from '../hooks';
import { 
  ChoiceBoxBanners, 
  InnerContainer,
  OrderId, 
  Products, 
  Section, 
  StatusBox, 
  StyledTransactionProductBox, 
} from './subscriptionDetailsCurrentOrder.styled';
import { 
  Breakpoint,
  ButtonTheme, 
  ChoiceBoxBanner, 
  ChoiceBoxBannerTheme, 
  InfoBox, 
  InfoBoxTheme, 
  PageTitle, 
  PageTitleSize, 
  ProgressBar,
  ResultInfoBox,
} from '@chic-loyalty/ui';
import { SubscriptionProduct } from '@chic/models';
import { useMediaQuery } from 'react-responsive';

export const SubscriptionDetailsCurrentOrderView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId }: Params = useParams();
  const { 
    currentOrder, 
    orderProgressBarStatuses, 
    currentOrderDelivery, 
    currentOrderPayment,
    actionsPermissions,
    subscriptionDetails,
  }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const { cancelOrder, manualConfirmDelivery, manualCreatePayment }: UseSubscriptionDetailsActions = useSubscriptionDetailsActions();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  return (
    <BaseViewForSubscriptionDetails 
      activeTab={SubscriptionDetailsPageName.CurrentOrder}    
      subscriptionId={subscriptionId}
      isNewOrderButtonActive={!!actionsPermissions.createOrder}
      bottomButtons={[
        ...(!!subscriptionDetails?.id && !!currentOrder?.id ? [
          {
            label: t('chic.management.subscriptionDetailsCurrentOrder.bottomButtons.cancelOrder'),
            buttonTheme: ButtonTheme.ICSecondary,
            action: (): void => {
              cancelOrder(subscriptionDetails.id, currentOrder.id);
            },
            disabled: !actionsPermissions.cancelOrder,
          },
          {
            label: t('chic.management.subscriptionDetailsCurrentOrder.bottomButtons.confirmDelivery'),
            buttonTheme: ButtonTheme.ICPrimary,
            action: (): void => {
              manualConfirmDelivery(
                subscriptionDetails.id, 
                currentOrder.deliveries[currentOrder.deliveries.length - 1].id,
              );
            },
            disabled: !actionsPermissions.manualConfirmDelivery,
          },
        ] : []),
      ]}
    >
      <InnerContainer>
        {!!currentOrder && !!subscriptionDetails?.id ? (
          <>
            <StatusBox>
              <OrderId>{t('chic.management.subscriptionDetailsCurrentOrder.orderId', { id: currentOrder.id })}</OrderId>
              <ProgressBar 
                tabs={orderProgressBarStatuses}
                activeStepName={currentOrder.status}
                errorMessage={currentOrder.status === SubscriptionOrderStatus.Failed 
                  ? t('chic.management.subscriptionDetailsCurrentOrder.status.failed')
                  : undefined
                }
              />
            </StatusBox>
            <Section>
              <PageTitle size={PageTitleSize.Small} label={t('chic.management.subscriptionDetailsCurrentOrder.deliveryAndPayment.title')} />
              <ChoiceBoxBanners>
                {!!currentOrderDelivery && (
                  <ChoiceBoxBanner data={currentOrderDelivery} />
                )}
                {!!currentOrderPayment && (
                  <ChoiceBoxBanner data={currentOrderPayment} theme={ChoiceBoxBannerTheme.Payment} isReversed={isMobile} />
                )}
              </ChoiceBoxBanners>
              {!!actionsPermissions.manualCreatePayment && (
                <InfoBox 
                  title={t('chic.management.subscriptionDetailsCurrentOrder.manualCreatePayment.title')} 
                  infoBoxTheme={InfoBoxTheme.Dark} 
                  buttonSettings={{
                    label: t('chic.management.subscriptionDetailsCurrentOrder.manualCreatePayment.buttonLabel'),
                    action: (): void => {
                      manualCreatePayment(
                        subscriptionDetails.id, 
                        currentOrder.id,
                        subscriptionDetails.contract.paymentType,
                      );
                    },
                    buttonTheme: ButtonTheme.ICText,
                  }}
                />
              )}
            </Section>
            <Section>
              <PageTitle size={PageTitleSize.Small} label={t('chic.management.subscriptionDetailsCurrentOrder.products.title')} />
              <Products>
                {currentOrder.products.map((product: SubscriptionProduct): JSX.Element => (
                  <StyledTransactionProductBox 
                    name={product.productName}
                    category={product.categoryName}
                    ean={product.ean}
                    amount={product.amount}
                    image={product.image ?? undefined}
                    price={product.unitPrice}
                    key={product.productId}
                  />
                ))}
              </Products>
            </Section>
            {/* TODO: restore when BE will be ready */}
            {/* <Section>
              <TrackingBanner 
                image={FileFromViews.SubscriptionDetailsCurrentOrderViewTrackingEditBanner} 
                imageMobile={FileFromViews.SubscriptionDetailsCurrentOrderViewTrackingEditBanner} 
                title={t('chic.management.subscriptionDetailsCurrentOrder.trackingEdit.title')} 
                buttonSettings={{
                  label: t('chic.management.subscriptionDetailsCurrentOrder.trackingEdit.buttonLabel'),
                  action: (): void => undefined,
                }} 
              />
            </Section> */}
          </>
        ) : (
          <ResultInfoBox 
            text={t('chic.management.subscriptionDetailsCurrentOrder.emptyState')} 
            backgroundImage={FileFromViews.SubscriptionDetailsCurrentOrderViewEmptyState} 
          />
        )}
      </InnerContainer>
    </BaseViewForSubscriptionDetails>
  );
};
