import { Color, FontWeight, Markdown, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  padding: 100px;
`;

export const Message: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 34px; 
  line-height: 32px;
  color: ${Color.ICWhite100};

  strong {
    color: ${Color.ICYellow100};
    font-weight: ${FontWeight.Bold};
  }
`;
