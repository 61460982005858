import { 
  Breakpoint, 
  CalcSize, 
  Color, 
  FontWeight, 
  InfoBox, 
  Markdown, 
  OrderSummaryBox, 
  PromotionBanner, 
  StyledComponent, 
  SubscriptionSummaryBox, 
  TransactionProductBox, 
  ZIndex, 
} from '@chic-loyalty/ui';
import styled, { css } from 'styled-components';
import { StyledInfoBoxProps } from './subscriptionEditProducts.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${Breakpoint.SmallMobile} {
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICBlack100};
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 28px 24px;
  background-color: ${Color.ICBlack200};
  
  @media ${Breakpoint.Mobile} {
    padding: 30px 24px;
    border-bottom: 1px solid ${Color.ICBlack100};
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const ProductsGrid: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 24px;
  margin: 24px 0;

  @media ${Breakpoint.Mobile} {
    margin: 40px 0;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 162px));
  }

  @media ${Breakpoint.SmallMobile} {
    gap: 10px;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  height: 120px;
  padding: 24px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: ${ZIndex.Min};

  @media ${Breakpoint.Mobile} {
    min-height: 104px;
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 28px;
  }
`;

export const ModalBoxInner: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 0 0 24px;

  & + & {
    padding: 24px 0;
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const TransactionProducts: StyledComponent<'div'> = styled.div`
  width: calc(100% - 48px);
  flex-grow: 1;
  overflow-y: auto;
  margin: 24px;
`;

export const StyledPromotionBanner: StyledComponent<typeof PromotionBanner> = styled(PromotionBanner)`
  width: 100%;
`;

export const StyledInfoBox: StyledComponent<typeof InfoBox, StyledInfoBoxProps> = styled(InfoBox)<StyledInfoBoxProps>`
  ${({ $isInModal }: StyledInfoBoxProps) => $isInModal ? css`
    width: calc(100% - 48px);
    margin 24px 24px 0;
  ` : css`
    margin-bottom: 20px;
  `};
`;

export const StyledSubscriptionSummaryBox: StyledComponent<typeof SubscriptionSummaryBox> = styled(SubscriptionSummaryBox)`
  border-bottom: 1px solid ${Color.ICBlack100};
`;

export const StyledOrderSummaryBox: StyledComponent<typeof OrderSummaryBox> = styled(OrderSummaryBox)`
  width: calc(100% - 48px);
  margin 0 24px;
`;

export const EmptyStateWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  height: 100%;
  padding: 0 24px;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const EmptyState: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  border: 1px solid ${Color.ICBlack100};
  color: ${Color.ICWhite100};
  padding: 80px 64px;

  @media ${Breakpoint.Mobile} {
    padding: 40px 20px;
  }
`;

export const EmptyStateDetails: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const EmptyStateTitle: StyledComponent<'p'> = styled.p`
  font-size: 28px;
  font-weight: ${FontWeight.ExtraBold};
  line-height: 28px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
  max-width: 580px;

  @media ${Breakpoint.Mobile} {
    font-size: 24px;
  }
`;

export const EmptyStateDescription: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  max-width: 494px;

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;
