import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { render, RenderOptions, RenderResult } from '@testing-library/react';

import { TestProvidersProps } from '@chic/models';
import { AuthContext, ConfigsContext } from '@chic/contexts';
import { authContextMock, configsContextMock } from './mocks';

const Providers: React.FC<TestProvidersProps> = ({ children }: TestProvidersProps): JSX.Element => {
  return (
    <ConfigsContext.Provider value={configsContextMock}>
      <AuthContext.Provider value={authContextMock}>
        <MemoryRouter>
          {children}
        </MemoryRouter>
      </AuthContext.Provider>
    </ConfigsContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const customRender = (ui: JSX.Element, options?: RenderOptions) => {
  const rendered: RenderResult = render(
    ui,
    { wrapper: Providers, ...options },
  );

  return {
    ...rendered,
    rerender: (
      uiItem: JSX.Element, optionsItem?: RenderOptions,
    ) => customRender(uiItem, { container: rendered.container, ...optionsItem }),
  };
};

// re-export everything
export * from '@testing-library/react';

export { customRender as render };
// override render method
